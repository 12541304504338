// api接口名和接口地址映射
import commonApi from './common'
import accessApi from './access' //权限接口
import orgApi from './setting/org' //设置-机构
import tenantCenterApi from './tenantCenter/index' //租户（超管的）
import msgApi from './setting/msg' //设置-消息
import logApi from './setting/log' //设置-日志
import noticeApi from './setting/notice' //设置-提醒
import dataDictApi from './setting/dataDict' //设置-字典
import accountPowerApi from './setting/accountPower' //设置-机构
import squareCenterApi from './squareCenter' //超管 - 应用
import operationRecordApi from './setting/operationRecord' //超管 - 应用
//2024-0319新增
import powerMannageApi from './setting/powerMannage' //设置-权限管理
//2024-0524新增
import collaborationManageApi from './setting/collaborationManage' //设置-协同管理
//2024-0626新增
import serveSettingApi from './setting/serveSetting'//设置-服务设置
import providerAndCustomeApi from './providerAndCustome'//公共的服务商和客户商接口
import employeeFilesApi from './employeeFiles/index'// 员工档案接口
// 2024-07-26新增
// import employeeProfileApi from './employeeProfile' //员工档案
export const apiData = {
  ...accessApi,
  ...commonApi,
  ...orgApi,
  ...tenantCenterApi,
  ...msgApi,
  ...accountPowerApi,
  ...noticeApi,
  ...squareCenterApi,
  ...dataDictApi,
  ...logApi,
  ...operationRecordApi,
  ...powerMannageApi,
  ...collaborationManageApi,
  ...serveSettingApi,
  ...providerAndCustomeApi,
  ...employeeFilesApi,
  // ...employeeProfileApi
}
