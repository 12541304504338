<template>
  <div>
    <div class="header">
      <div class="header-box fbc">
        <div class="fc">
          <HeaderLogo></HeaderLogo>
          <div class="appWarp fc">
            <div
              v-for="item in newAppInletList"
              :key="item.key"
              class="app-item-box"
            >
              <div
                @click="goPage(item)"
                class="app-item fcc"
                :class="{ 'app-item-active': item.key === currentKey }"
                :to="item.path"
              >
                {{ item.label }}
                <HeaderService
                  v-if="item.key === 'service' && actionList.length"
                  class="appItem-hoverBox"
                >
                </HeaderService>
              </div>
            </div>
          </div>
        </div>
        <div class="admin-header-right fec">
          <HeaderNotice
            style="margin-right: 20px; margin-top: 6px"
          ></HeaderNotice>
          <!--          <div class="header-right-button fcc" v-if="role != 'ADMIN'">-->
          <!--            <el-button v-if="from === 'SQUARE'" type="primary" @click="goWorkerPlace()">进入工作台</el-button>-->
          <!--            <el-button v-if="from === 'DEFAULT' && role != 'ADMIN'" type="primary"-->
          <!--              @click="$router.push('/home/appSquare')">应用广场</el-button>-->
          <!--          </div>-->
          <HeaderAvatar></HeaderAvatar>
        </div>
      </div>
    </div>
    <div class="header-seat"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import HeaderAvatar from './HeaderAvatar.vue';
import HeaderNotice from './HeaderNotice.vue';
import HeaderService from './HeaderService.vue';
import HeaderLogo from './HeaderLogo.vue';
import { publicAccoutTableTitle } from '@/views/setting/accountPower/formdes';
export default {
  name: 'LayoutHeader',
  components: {
    HeaderAvatar,
    HeaderNotice,
    HeaderService,
    HeaderLogo,
    publicAccoutTableTitle,
  },
  data() {
    return {
      appInletList: [],
      appInlet: {
        square: [{ label: '应用广场', path: '/home/appSquare', key: 'home' }],
        CUSTOMER: [
          // { label: '首页', path: '/home/workerPlace', key: 'home' },
          // { label: '服务', path: '/service', key: 'service' },
          { label: '设置', path: '/setting/userInfo', key: 'setting' },
        ],
        OPERATION: [
          {
            label: '租户',
            path: '/tenantCenter/tenantManage',
            key: 'tenantCenter',
          },
          { label: '设置', path: '/setting/userInfo', key: 'setting' },
        ],
        ADMIN: [
          { label: '租户', path: '/tenantCenter', key: 'tenantCenter' },
          {
            label: '应用',
            path: '/squareCenter/squareManage',
            key: 'squareCenter',
          },
          {
            label: '设置',
            path: '/setting/powerMannage/sysMenu',
            key: 'setting',
          },
        ],
      },
      currentKey: '',
      actionList: [],
      actionListWidth: 0,
      sceneCode: 'BASE',
      squareList: ['/home/appSquare', '/home/appDetail'],
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.user.role,
      userInfo: (state) => state.user.userInfo,
      routes: (state) => state.permission.routes,
    }),
    from() {
      if (this.squareList.includes(this.$route.path)) {
        return 'SQUARE';
      } else {
        return 'DEFAULT';
      }
    },
    newAppInletList() {
      let key = this.from === 'SQUARE' ? 'square' : this.role || 'CUSTOMER';
      // if (this.from === 'SQUARE') key = 'CUSTOMER'
      this.appInletList = this.appInlet[key];
      const isAppSquare = this.$route.path == '/home/appSquare';
      const { appInlet, appInletList } = this;
      console.log('isAppSquare', isAppSquare, appInletList, key, (isAppSquare ? appInlet.square : appInletList).sort(
        (a, b) => b.moduleId - a.moduleId
      ))
      return (isAppSquare ? appInlet.square : appInletList).sort(
        (a, b) => b.moduleId - a.moduleId
      );
    },
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        const str = newVal.path;
        const parts = str.split('/');
        this.currentKey = `/${parts[1]}`;
      },
      immediate: true,
    },
  },
  created() {
    this.functionModuleListTenantGroup();
    this.getAppInletList();
  },
  methods: {
    //获取header的模块列表
    getAppInletList() {
      const appInletList = [];
      this.routes.forEach((v) => {
        const _array = {
          label: v.moduleName,
          path: v.path,
          key: v.path,
          moduleId: v.moduleId,
        };
        if (v.children && v.children.length) {
          _array.path = v.children[0].path || v.children[0].linkPath;
        } else {
          _array.path = '/404';
        }
        if (this.role === 'CUSTOMER' && v.name === 'home') {
          _array.path = '/home/workerPlace';
        }
        appInletList.push(_array);
      });
      this.appInlet[this.role] = appInletList;
    },
    async functionModuleListTenantGroup() {
      try {
        const res = await this.$httpServer.functionModuleListTenantGroup({
          enable: true,
        });
        if (res.code == 0) {
          let width = 0;
          res.data.forEach((item) => {
            let num = item.moduleList.length || 0;
            if (num <= 2) {
              num = 2;
            }
            const _width = (num / 2) * 230;
            width += _width;
          });
          this.actionListWidth = width;
          this.actionList = res.data;
        }
      } catch (error) {}
    },
    goWorkerPlace() {
      const routes = this.$store.state.permission.routes;
      if (routes && routes.length) {
        this.$router.push(routes[0].redirect);
      } else {
        this.$message.info('暂无权限');
      }
    },
    goPage(item) {
      if (item.path === this.$route.path) {
        this.$store.state.user.num++;
        return;
      }
      if (item.key === 'service') return false;
      this.$router.push(item.path);
    },
  },
};
</script>
<style lang="scss" scoped>
.header-seat {
  width: 100%;
  height: 63px;
  border: 1px solid #f5f7fa;
}

.header {
  width: 100%;
  height: 63px;
  border-bottom: 1px solid #f5f7fa;
  min-width: 1120px;
  box-sizing: border-box;
  color: #000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  // -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  // box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  background: #fff;

  .header-box {
    position: relative;
    padding: 0 16px;
  }

  .header-title {
    font-size: 17px;
    font-family: HuXiaoBo;
    font-weight: 400;
    line-height: 26px;
    vertical-align: top;
    margin-left: 8px;
  }

  .appWarp {
    margin-left: 132px;
  }

  .app-item {
    padding: 5px 16px;
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
    color: #333333;
    font-weight: 400;
    margin: 18px 12px;

    &:hover {
      color: $theme;
    }

    .appItem-hoverBox {
      display: none;
      // display: block;
    }
  }

  .app-item-active {
    background-color: #ddeaff;
    color: $theme;
    font-family: PingFangSC-Bold;
  }

  .app-item-box {
    height: 63px;

    &:hover {
      .appItem-hoverBox {
        display: block;
      }
    }
  }
}

.admin-header-right {
  width: 250px;
}

.header-right-button {
  height: 63px;
  margin-right: 24px;
}
</style>
