import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user' // 用户信息模块组
import layout from './modules/layout'
import permission from './modules/permission'
import auxiliary from './modules/auxiliary'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user, // 用户信息
    layout,
    permission,//权限信息
    auxiliary,//辅助仓库
  }
})
