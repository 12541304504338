
import storage from '@/store/storage'

const auxiliary = {
  state: {
    // 配合消息通知点击进入页面的弹窗使用
    msgItem: storage.getLocalItem("msgItem") || null,
  },
  mutations: {
    setMsgItem(state, e) {
      state.msgItem = e
      storage.setLocalItem("msgItem", e)
    }
  },
  actions: {},
  getters: {}
};

export default auxiliary;
