/*
 * @Descripttion: 设置 - 组织机构
 * @Author: hehua
 * @Date: 2024-01-26 17:45:02
 * @LastEditors: hehua
 * @LastEditTime: 2024-06-24 22:00:54
 */
const baseUrl = "api/drsp_operation/org"
const departBaseUrl = "api/drsp_operation/department"
const employeeBaseUrl = "api/drsp_operation/employee"
const positionBaseUrl = "api/drsp_operation/position"

export default {
  ///************************组织管理************************
  //获取所有的下级机构树形结构
  orgAllChildrenTree: { url: `${baseUrl}/all/children/tree`, method: "get" },
  //获取所有的下级机构列表
  orgAllChildrenList: { url: `${baseUrl}/all/children/list`, method: "get" },
  //创建机构
  orgCreate: { url: `${baseUrl}/create`, method: "post" },
  //注销机构
  orgDisable: { url: `${baseUrl}/disable`, method: "get" },
  //导出机构
  orgExport: { url: `${baseUrl}/export`, method: "post" },
  //导入机构
  orgImport: { url: `${baseUrl}/import`, method: "post" },
  //下载机构导入模板
  orgDownload: { url: `${baseUrl}/import/template/download`, method: "get" },
  //修改机构
  orgModify: { url: `${baseUrl}/modify`, method: "post" },
  //分页查询机构
  orgPage: { url: `${baseUrl}/page`, method: "get" },
  //调整父级
  orgParentAdjust: { url: `${baseUrl}/parent/adjust`, method: "get" },
  //设置机构
  orgSetting: { url: `${baseUrl}/setting`, method: "post" },
  //获取机构设置信息
  orgSettingInfo: { url: `${baseUrl}/setting/info`, method: "get" },
  //根据id查询机构详情
  orgDetailWithId: { url: `${baseUrl}/detail_with_id`, method: "get" },
  //机构配置设置
  orgConfigSetting: { url: `${baseUrl}/config/setting`, method: "post" },
  //查询配置属性列表
  configPropertiesList: {
    url: `api/drsp_operation/config/properties/list`,
    method: "post",
  },
  //机构配置信息列表
  orgConfigInfList: { url: `${baseUrl}/config/info/list`, method: "get" },

  ///************************部门管理************************

  // 根据上级机构ID获取机构部门树
  departOrgTree: {
    contentType: "multipart/form-data",
    url: `${departBaseUrl}/org/tree`,
    method: "post",
  },
  // 只有部门的树
  departTree: {
    contentType: "multipart/form-data",
    url: `${departBaseUrl}/tree`,
    method: "post",
  },
  //新增部门
  departCreate: { url: `${departBaseUrl}/create`, method: "post" },
  //删除部门
  departDelete: { url: `${departBaseUrl}/delete`, method: "post" },
  //获取部门信息
  departDetailWithId: { url: `${departBaseUrl}/detail_with_id`, method: "get" },
  //部门长列表
  departList: { url: `${departBaseUrl}/list`, method: "post" },
  //修改部门
  departModify: { url: `${departBaseUrl}/modify`, method: "post" },
  //分页查询部门
  departPage: { url: `${departBaseUrl}/page`, method: "post" },
  // 设置部门主管
  departmentSetting: { url: `${departBaseUrl}/setting`, method: "post" },

  ///************************人员管理************************
  //新增员工
  employeeCreate: { url: `${employeeBaseUrl}/create`, method: "post" },
  //删除员工
  employeeDelete: { url: `${employeeBaseUrl}/delete`, method: "post" },
  //获取员工信息
  employeeDetailWithId: {
    url: `${employeeBaseUrl}/detail_with_id`,
    method: "get",
  },
  //员工长列表
  employeeList: { url: `${employeeBaseUrl}/list`, method: "post" },
  //修改员工
  employeeModify: { url: `${employeeBaseUrl}/modify`, method: "post" },
  //分页查询员工
  employeePage: { url: `${employeeBaseUrl}/page`, method: "post" },
  //下载员工导入模板
  employeeDownload: {
    url: `${employeeBaseUrl}/import/template/download`,
    method: "get",
  },
  //导出员工列表
  employeeExport: { url: `${employeeBaseUrl}/export`, method: "post" },
  //导入员工列表
  employeeImport: { url: `${employeeBaseUrl}/import`, method: "post" },
  // 设置领导
  settingLeader: { url: `${employeeBaseUrl}/setting/leader`, method: "post" },

  // 岗位分页查询
  positionPage: { url: `${positionBaseUrl}/page`, method: "post" },
  // 岗位下拉列表
  positionList: { url: `${positionBaseUrl}/list`, method: "post" },
  // 新增岗位
  positionCreate: { url: `${positionBaseUrl}/create`, method: "post" },
  // 修改岗位
  positionModify: { url: `${positionBaseUrl}/modify`, method: "post" },
  // 岗位详细信息
  positionDetailWithId: {
    url: `${positionBaseUrl}/detail_with_id`,
    method: "get",
  },
  //删除岗位
  positionDelete: { url: `${positionBaseUrl}/delete`, method: "post" },
}
