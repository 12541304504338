import router from '../router'
const whiteList = [
  '/login',
  '/forget',
  '/disclaimer',
  '/agreement',
  '/loadingLogin'
] // no redirect whitelist
// let applicationPathList = ['/cleverWorkOrder','/sparePartsManager','/performanceManagement']
import store from "@/store"
// 每一个路由的页面都要经过这里，相当于拦截器
router.beforeEach((to, from, next) => {
  to.meta.isRefresh = (!from.matched || from.matched.length === 0);
  /* has token */
  const { accessToken, userInfo } = store.state.user
  if (accessToken && userInfo) {
    // 判断是否有token
    if (to.path === '/loadingLogin') {
      next()
    } else if (whiteList.includes(to.path)) {
      next('/')
    } else {
      //判断用户是否已经加载过权限
      if (!store.state.permission.hasSetting) {
        store.dispatch('generateRoutes')
          .then((res) => {
            //初始化  tag
            router.addRoutes(res)
            next({ ...to, replace: true })
          })
      } else {
        next() //当有用户权限的时候，说明所有可访问路由已生成 如访问没权限的全面会自动进入404页面
      }
    }
  } else {
    if (whiteList.includes(to.path)) {
      // 在免登录白名单，直接进入
      // 此处的next没有to参数，直接到当前路由指向的组件，是循环的结束条件
      next()
    } else {
      // 没有token且访问的页面不在免登录白名单中，则先跳转到登录页
      next('/login')
    }
  }
})