
/*
 * @Descripttion: 协同管理相关接口
 * @Author: hehua
 * @Date: 2024-05-26 16:54:09
 * @LastEditors: hehua
 * @LastEditTime: 2024-05-29 15:10:46
 */
const baseUrl = 'api/drsp_elf/customer_service_provider'
const serviceUrl = 'api/drsp_elf/external_service_provider'
export default {
  //分页查询客户商
  getCustomerProviderPage: { url: `${baseUrl}/page`, method: 'post' },
  //导出客户商
  exportCustomerProvider: { url: `${baseUrl}/export`, method: 'post' },
  //新增客户商
  createCustomerProvider: { url: `${baseUrl}/create`, method: 'post' },
  //编辑客户商
  updateCustomerProvider: { url: `${baseUrl}/update`, method: 'post' },
  // 删除服务商
  deleteCustomerProvider: { url: `${baseUrl}/delete`, method: 'delete' },
  //查询客户的网点信息
  getCustomerProviderOrgPage: { url: `${baseUrl}/org/page`, method: 'post' },
  //客户商查询协同租户列表
  getCustomerTenantCooperateList: { url: `${baseUrl}/tenant_cooperate_list`, method: 'post' },
  //查询客户商详情
  getCustomerProviderDetail: { url: `${baseUrl}`, method: 'get', isParam: true },
  //分页查询服务商
  getServiceProviderPage: { url: `${serviceUrl}/page`, method: 'post' },
  //导出服务商
  exportServiceProvider: { url: `${serviceUrl}/export`, method: 'post' },
  //新增服务商
  createServiceProvider: { url: `${serviceUrl}/create`, method: 'post' },
  //编辑服务商
  updateServiceProvider: { url: `${serviceUrl}/update`, method: 'post' },
  // 删除服务商
  deleteServiceProvider: { url: `${serviceUrl}/delete`, method: 'delete' },
  //查询服务商的网点信息
  getServiceProviderOrgPage: { url: `${serviceUrl}/org/page`, method: 'post' },
  //服务商查询协同租户列表
  getTenantCooperateList: { url: `${serviceUrl}/tenant_cooperate_list`, method: 'post' },
  //查询服务商商详情
  getTenantCooperateDetail: { url: `${serviceUrl}`, method: 'get', isParam: true },
  // 查询全部服务商分配的机构
  getExternalServiceProviderOrgList: { url: `${serviceUrl}/org/list`, method: 'get' },
  // 服务商绑定机构
  externalServiceProviderOrgBind: { url: `${serviceUrl}/org/bind`, method: 'post' },

}