/*
 * @Descripttion: 字典相关接口
 * @Author: chenrui
 * @Date: 2024-02-01
 * @LastEditors: hehua
 * @LastEditTime: 2024-02-21 21:08:37
 */
const baseUrl = 'api/drsp_operation/dictionary'
export default {
    dictionaryCreate: { url: `${baseUrl}/create`, method: 'post' },
    dictionaryDelete: { url: `${baseUrl}/delete`, method: 'post' },
    dictionaryUpdate: { url: `${baseUrl}/update`, method: 'post' },
    dictionaryDetail: { url: `${baseUrl}/detail`, method: 'get' },
    dictionaryList: { url: `${baseUrl}/list`, method: 'post' },
    dictionaryValueCreate: { url: `${baseUrl}/value/create`, method: 'post' },
    dictionaryValueDelete: { url: `${baseUrl}/value/delete`, method: 'post' },
    dictionaryValueUpdate: { url: `${baseUrl}/value/update`, method: 'post' },
    dictionaryValueDetail: { url: `${baseUrl}/value/detail`, method: 'get' },
    dictionaryValueList: { url: `${baseUrl}/value/list`, method: 'get' },
}