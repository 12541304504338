const layout = {
  state: {
    // 菜单展开(false)/菜单收起(true)
    isCollapse: false,
  },
  mutations: {
    setMenuCollapse(state, e) {
      state.isCollapse = e
    }
  },
  actions: {},
  getters: {}
};

export default layout;
