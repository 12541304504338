<template>
  <el-dropdown trigger="click" ref="dropdown">
    <div class="header-notice fcc" style="cursor: pointer">
      <el-badge :value="unReadNumber" class="badgeItem" v-if="unReadNumber > 0">
        <img class="header-notice-icon" src="@/assets/imgs/layout/notice.png" alt>
      </el-badge>
      <img class="header-notice-icon" src="@/assets/imgs/layout/notice.png" v-else alt>
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-container class="sort-container">
        <el-main class="sort-main">
          <div class="fbc">
            <el-tabs class="sort-main-tab" v-model="activeName">
              <el-tab-pane label="未读" name="first"></el-tab-pane>
              <el-tab-pane label="全部" name="second"></el-tab-pane>
            </el-tabs>
            <div class="allRead fec" @click="markAllAsHasRead">
              <i class="el-icon-message allRead-icon"></i>
              <p class="allRead-title">全部标为已读</p>
            </div>
          </div>
          <div class="notice-item-content" v-if="activeName == 'first'">
            <template v-if="unreadList.length > 0">
              <div @click="goPage(item)" class="notice-item" v-for="(item, index) in unreadList" :key="index">
                <div class="notice-info fbc">
                  <div class="fc">
                    <!-- <img class="header-info-icon"
                      :src="require(`@/assets/imgs/layout/${!item.isRead ? 'read' : 'unRead'}.png`)" alt> -->
                    <svg-icon class="header-info-icon" name="notice"></svg-icon>
                    <p class="notice-info-title">{{ item.title }}</p>
                  </div>
                  <p class="notice-info-time">{{ $formatDate(item.createTime, 'yyyy-MM-dd hh:mm') }}</p>
                </div>
                <p class="notice-desc">{{ item.content }}</p>
              </div>
            </template>
            <template v-else>
              <el-empty description="暂无通知"></el-empty>
            </template>
          </div>
          <div class="notice-item-content" v-if="activeName == 'second'">
            <template v-if="msgList.length > 0">
              <div @click="goPage(item)" class="notice-item" v-for="(item, index) in msgList" :key="index">
                <div class="notice-info fbc">
                  <div class="fc">
                    <svg-icon class="header-info-icon" name="notice"></svg-icon>
                    <p class="notice-info-title">{{ item.title }}</p>
                  </div>
                  <p class="notice-info-time">{{ $formatDate(item.createTime, 'yyyy-MM-dd hh:mm') }}</p>
                </div>
                <p class="notice-desc">{{ item.content }}</p>
              </div>
            </template>
            <template v-else>
              <el-empty description="暂无通知"></el-empty>
            </template>
          </div>
        </el-main>
      </el-container>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { RouterLink } from "vue-router"
import msgApi from "@/api/setting/msg.js"
import { EventSourcePolyfill } from "event-source-polyfill"

export default {
  name: "HeaderAvatar",
  data() {
    return {
      activeName: "first",
      msgList: [],
      unReadNumber: 0,
      eventSource: null,
      isSendNotice: false,
    }
  },
  components: { RouterLink },
  created() {
    //解决首次进来时，tabactive下划线宽度为0的element bug问题
    this.$nextTick(_ => {
      const activeBarElement = document.getElementsByClassName(
        "el-tabs__active-bar"
      )[0]
    })
    this.getMsgList()
    this.getMsgNumber()
    this.judgeSseConnect()
    this.createSseConnect()
  },
  mounted() {
    //重新获取角标数量及刷新通知列表
    this.$EventBus.$on('eventBus_getUnReadNum', () => {
      this.getMsgList()
      this.getMsgNumber()
    })
    //改变是否需要弹窗通知
    this.$EventBus.$on('eventBus_changeSseConnect', (res) => {
      this.isSendNotice = !!res
    })
  },
  beforeDestroyed() {
    this.eventSource.close();
    this.eventSource = null;
    this.$EventBus.$off('eventBus_getUnReadNum')
    this.$EventBus.$off('eventBus_changeSseConnect')
  },
  computed: {
    unreadList() {
      return this.msgList?.filter(x => !x.isRead)
    },
    readList() {
      return this.msgList.filter(x => x.isRead)
    }
  },
  methods: {
    //根据系统设置判断是否需要弹窗通知消息
    async judgeSseConnect() {
      try {
        let res = await this.$httpServer.userMessageRemindList({})
        let bollen = res.data[0].enable
        this.isSendNotice = bollen
      } catch (error) {
        console.log('error-->', error)
      }
    },
    //获取消息列表
    async getMsgList() {
      try {
        const res = await this.$httpServer.messageRecordList({})
        this.msgList = res.data
      } catch (error) {
        console.log('error-->', error)
      }
    },
    //标记全部消息为已读
    async markAllAsHasRead() {
      const res = await this.$httpServer.messageRecordModifyReadStatus({
        isRead: true
      })
      this.$refs.dropdown.hide()
      if (res.code == 0) {
        this.$EventBus.$emit('eventBus_msgRead')
        this.getMsgList()
        this.getMsgNumber()
      }
    },
    //获取未读角标数量
    async getMsgNumber() {
      try {
        const res = await this.$httpServer.messageRecordUnReadTotal({})
        if (res.code == 0) {
          this.unReadNumber = res.data
        }
      } catch (error) {
        console.log('error-->', error)
      }
    },
    //判断该字符串是否能被序列化
    isSerializable(str) {
      try {
        JSON.parse(str)
        return true
      } catch (error) {
        return false
      }
    },
    //打开弹窗
    openNotify(data) {
      const h = this.$createElement
      this.$notify && this.$notify.closeAll()
      this.$notify.warning({
        title: data.title || '--',
        dangerouslyUseHTMLString: true,
        position: "top-right",
        offset: 50,
        message: h("p", null, [h("p", null, `${data.content || "--"}`)]),
        duration: 3000
      })
    },
    //sse长链接接收预警弹窗
    createSseConnect() {
      const uid = Date.now()
      if (window.EventSource) {
        const url = "/" + msgApi.messageConnect.url + "?lastEventId=" + uid
        const token = this.$store?.state?.user?.accessToken || ""
        this.eventSource = new EventSourcePolyfill(url, {
          headers: {
            'Content-Type': 'text/event-stream',
            'Cache-Control': 'no-cache',
            'Connection': 'keep-alive',
            'X-Accel-Buffering': 'no',
            token
          },
          heartbeatTimeout: 5 * 60 * 60 * 1000,//heartbeatTimeout最大值
          withCredentials: true,
          errorOnTimeout: false
        })
        this.eventSource.onmessage = e => {
          if (!e.data) return false
          const isJson = this.isSerializable(e.data)
          if (!isJson) return false
          const data = JSON.parse(e.data)
          if (typeof data == "object" && !data.code) {
            if (data.messageType == "TEST_MESSAGE") return false
            this.getMsgList()
            this.getMsgNumber()
            this.isSendNotice && this.openNotify(data)
            //手动关闭重连
            // if (this.eventSource) {
            // this.eventSource.close()
            // this.createSseConnect()
            // }
          }
        }
        this.eventSource.onopen = e => {
          // console.log("已建立SSE连接~")
        }
        this.eventSource.onerror = e => {
          if (process.env.NODE_ENV == "development") {
            // this.eventSource.close()
            // console.log("development环境下连接失败后自动关闭重连")
          }
        }
        // this.eventSource.addEventListener("message", event => {
        //   console.log("lastEventId id is " + event.lastEventId)
        // })

      } else {
        this.$alert("你的浏览器不支持SSE,无法实时告警", "提示！", {
          confirmButtonText: "确定"
        })
      }
    },
    goPage(item) {
      this.$refs.dropdown.hide()
      this.$store.commit('setMsgItem', item)
      if (this.$route.path !== '/message/redMsg') {
        sessionStorage.setItem('msgItem', JSON.stringify(item))
        this.$router.push('/message/redMsg')
      }
    }
  },
  beforeDestroy() {
    //手动关闭重连
    if (this.eventSource) this.eventSource.close()
  },
}
</script>

<style lang="scss" scoped>
.header-notice {
  display: inline-flex;

  .header-notice-icon {
    width: 28px;
  }

  .avatar,
  .name {
    align-self: center;
  }

  .avatar {
    margin-right: 8px;
  }
}

.sort-container {
  .sort-main {
    width: 316px;
    border-radius: 10px;
    box-sizing: border-box;
    // padding: 0 20px;
    padding: 0;

    .allRead {
      flex: 1;
      height: 69px;
      margin-right: 24px;
      border-bottom: 1px solid #e5eaf2;
      padding-bottom: 14px;
      cursor: pointer;
      color: rgba(51, 51, 51, 0.8);

      &:hover {
        color: #317dff;
      }

      .allRead-icon {
        width: 20px;
        height: 20px;
        margin-top: 6px;
        font-size: 16px;
      }

      .allRead-title {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .notice-item-content {
      width: 100%;
      max-height: 450px;
      overflow-y: auto;

      .notice-item {
        padding: 10px 0;
        margin: 0 20px;
        box-sizing: border-box;
        display: block;
        border-bottom: 1px solid #eee;
        cursor: pointer;

        &:hover {

          .notice-info-title,
          .notice-info-time,
          .notice-desc,
          .header-info-icon {
            color: #317dff !important;
          }
        }

        &:nth-last-child(1) {
          border-bottom: none;
        }

        .notice-info {
          padding-top: 5px;
          padding-bottom: 5px;
          font-size: 14px;

          .header-info-icon {
            width: 20px;
            height: 20px;
            color: #6a737b;
          }

          .notice-info-title {
            padding-left: 8px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #6a737b;
            line-height: 20px;
          }

          .notice-info-time {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(51, 45, 64, 0.6);
            line-height: 17px;
          }
        }

        .notice-desc {
          padding-top: 10px;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #757e89;
          line-height: 18px;
        }
      }
    }

    .sort-main-tab {
      padding-left: 20px;

      ::v-deep .el-tabs__nav-wrap {
        box-shadow: none;
        height: 68px;
      }

      ::v-deep .el-tabs__nav-scroll {
        background-color: transparent;
      }

      ::v-deep .el-tabs__nav-wrap::after {
        height: 1px;
        background-color: #e5eaf2;
      }

      ::v-deep .el-tabs__item {
        font-size: 16px;
        font-family: PingFangSC-Bold;
        color: rgba(51, 51, 51, 0.8);
      }

      ::v-deep .el-tabs__item:hover {
        color: #317dff !important;
      }

      ::v-deep .el-tabs__item.is-active {
        font-family: PingFangSC-Bold;
        color: rgba(0, 0, 0, 0.9);
      }

      ::v-deep .el-tabs__item.is-top:nth-child(2) {
        padding-left: 0 !important;
      }

      ::v-deep .el-tabs__active-bar {
        height: 4px;
        background: #317dff;
        border-radius: 2px;
        width: 24px !important;
        margin-left: 4px;
      }

      ::v-deep .el-tabs__header {
        margin-bottom: 0;
      }
    }
  }
}

.badgeItem {
  ::v-deep .el-badge__content {
    border-bottom-left-radius: 0 !important;
    top: 6px;
    right: 14px;
  }
}

::v-deep .el-badge__content {
  background-color: #FB4B52;
  color: #fff;
}
</style>
