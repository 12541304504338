/*
 * @Descripttion: 权限相关接口
 * @Author: hehua
 * @Date: 2024-02-01:20:44
 * @LastEditors: hehua
 * @LastEditTime: 2024-03-20 17:06:19
 */
const baseUrl = 'api/drsp_operation/access'
const userBaseUrl = 'api/drsp_operation/user'
export default {
  // 查询模块权限列表
  accessChildrenList: { url: `${baseUrl}/children/list`, method: 'get' },
  // 新增权限
  accessCreate: { url: `${baseUrl}/create`, method: 'post' },
  // 权限信息
  accessInfo: { url: `${baseUrl}/info`, method: 'get' },
  // 删除权限
  accessDelete: { url: `${baseUrl}/delete`, method: 'get' },
  // 修改权限
  accessModify: { url: `${baseUrl}/modify`, method: 'post' },
  // 应用管理 - 获取对应模块的树， 查询功能权限树列表
  accessModuleTree: { url: `${baseUrl}/module/tree`, method: 'get' },
  // 获取权限树形结构 
  accessTree: { url: `${baseUrl}/tree`, method: 'get' },
  // 功能权限授权
  accessModuleGrant: { url: `${baseUrl}/module/grant`, method: 'post' },
  // 查询所有功能权限树列表
  accessModuleTreeAll: { url: `${baseUrl}/module/tree/all`, method: 'get' },
  //租户管理- 租户权限授权
  accessTenantGrant: { url: `${baseUrl}/tenant/grant`, method: 'post' },
  //租户管理-查询租户权限树形结构
  accessTenantTree: { url: `${baseUrl}/tenant/tree`, method: 'get' },

  //20240320新增--
  // 获取用户权限
  getUserAccessInfo: { url: `${userBaseUrl}/access/info`, method: 'get' },
}