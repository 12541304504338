<template>
  <div class='HeaderLogo'>
    <img v-if="showLogo" class="header-logo" src="@/assets/imgs/layout/logo.png" alt="">
    <div class="fc" v-else>
      <el-image class="header-imgLogo" :src="userInfo.logo" fit="cover" />
      <p class="header-tenantName row-1">{{ userInfo.tenantName }}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HeaderLogo',
  components: {},
  data() {
    return {
      showLogoPaths: [
        '/home/appSquare',
        '/home/appDetail',
      ]
    }
  },
  computed: {
    //当前页面是否展示logo
    showLogo() {
      if (this.$store.state.user.role == 'ADMIN') return true
      return this.showLogoPaths.includes(this.$route.path)
    },
    userInfo() {
      return this.$store.state.user.userInfo
    }
  },
  methods: {

  },
  created() {

  },
  mounted() {

  }
}
</script>
<style lang='scss' scoped>
.HeaderLogo {
  min-width: 277px;

  .header-logo {
    width: 277px;
  }

  .header-imgLogo {
    width: 24px;
    height: 24px;
    object-fit: cover;
  }

  .header-tenantName {
    font-size: 15px;
    padding-left: 10px;
    font-family: PingFangSC-Bold;
    max-width: 188px;
  }
}
</style>