import Vue from 'vue'
import esConfirm from './index.vue'

let newInstance
const ConfirmInstance = Vue.extend(esConfirm)

const initInstance = () => {
  newInstance = new ConfirmInstance()
  document.body.appendChild(newInstance.$mount().$el)
}

export default options => {
  if (!newInstance) {
    initInstance()
  }
  Object.assign(newInstance, options)
  return newInstance.show(vm => {
    newInstance = null
  })
}