import Vue from 'vue'
import VueRouter from 'vue-router'
import baseRoutes from "./baseRoutes"
Vue.use(VueRouter)

//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}


const createRouter = () => new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: baseRoutes
})
/*
解决:退出登录再登录后 控制台路由命命名重复警告问题

发现自己的name并没有重名,那么一般是后台动态路由导致的报错警告
动态路由一般来说是通过后端接口返回拿到数据,然后在路由守卫router.beforeEach中进行添加
addRoutes方法仅仅是帮你注入新的路由,并没有帮你剔除其他路由
*/

const router = createRouter();
// 重置路由
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // the relevant part
}

export default router
