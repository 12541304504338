const baseUrl = 'api/drsp_operation';
export default {
  // 档案人员分页列表
  employeeArchivesList: {
    url: `${baseUrl}/employee/archives/page`,
    method: 'post',
  },
  // 导出人员档案列表
  employeeArchivesExport: {
    url: `${baseUrl}/employee/archives/export`,
    method: 'post',
  },
  // 下载人员档案导入模板
  employeeArchivesImportExport: {
    url: `${baseUrl}/employee/archives/import/template/download`,
    method: 'get',
  },
  // 导入人员档案
  employeeArchivesImport: {
    url: `${baseUrl}/employee/archives/import`,
    method: 'post',
  },
  // 职位不分页列表
  postList: { url: `${baseUrl}/post/list`, method: 'post' },
  // 职级不分页列表
  postLevelList: { url: `${baseUrl}/postLevel/list`, method: 'post' },
  // 获取基础信息
  employeeDetailByUserid: {
    url: `${baseUrl}/employee/archives/detail_with_userid`,
    method: 'get',
  },
  // 修改基础信息
  employeeArchivesUpdate: {
    url: `${baseUrl}/employee/archives/update`,
    method: 'post',
  },
  // 修改基础信息
  employeeArchivesEdit: {
    url: `${baseUrl}/employee/archives/update`,
    method: 'post',
  },
  // 获取入职信息
  onboardingDetailByUserid: {
    url: `${baseUrl}/onboarding/detail_by_userid`,
    method: 'get',
  },
  // 修改入职信息
  onboardingUpdate: { url: `${baseUrl}/onboarding/update`, method: 'post' },
  // 修改入职信息
  onboardingEdit: { url: `${baseUrl}/onboarding/edit`, method: 'post' },
  // 获取劳务合同
  laborContractListByUserid: {
    url: `${baseUrl}/laborContract/list_by_userid`,
    method: 'get',
  },
  // 修改劳务合同
  laborContractBatchEdit: {
    url: `${baseUrl}/laborContract/batch_edit`,
    method: 'post',
  },
  // 获取工作经历
  workHistoryListByUserid: {
    url: `${baseUrl}/work_history/list_by_userid`,
    method: 'get',
  },
  // 修改工作经历
  workHistoryBatchEdit: {
    url: `${baseUrl}/work_history/batch_edit`,
    method: 'post',
  },
  // 获取教育信息
  educationInfoListByUserid: {
    url: `${baseUrl}/education_info/list_by_userid`,
    method: 'get',
  },
  // 修改教育信息
  educationInfoBatchEdit: {
    url: `${baseUrl}/education_info/batch_edit`,
    method: 'post',
  },
  // 获取证书信息
  certificateListByUserid: {
    url: `${baseUrl}/certificate/list_by_userid`,
    method: 'get',
  },
  // 修改证书信息
  certificateBatchEdit: {
    url: `${baseUrl}/certificate/batch_edit`,
    method: 'post',
  },
  // 批量设置部门信息
  employeeSettingDepartment: {
    url: `${baseUrl}/employee/setting/department`,
    method: 'post',
  },
  // 批量设置在职状态
  employeeSettingWorkStatus: {
    url: `${baseUrl}/employee/setting/workStatus`,
    method: 'post',
  },
  // 劳务合同分页列表
  laborContractPage: { url: `${baseUrl}/laborContract/page`, method: 'post' },
  // 新增劳务合同
  laborContractCreate: {
    url: `${baseUrl}/laborContract/create`,
    method: 'post',
  },
  // 编辑劳务合同
  laborContractEdit: { url: `${baseUrl}/laborContract/update`, method: 'post' },
  // 删除劳务合同
  laborContractDelete: {
    url: `${baseUrl}/laborContract/delete`,
    method: 'post',
  },
  // 单个劳务合同详情
  laborContractDetail: {
    url: `${baseUrl}/laborContract/detail`,
    method: 'get',
  },
  // 导出劳务合同列表
  laborContractExport: {
    url: `${baseUrl}/laborContract/export`,
    method: 'post',
  },
  // 员工档案数据字典列表
  dictionaryArchivesList: {
    url: `${baseUrl}/dictionary/archives/list`,
    method: 'post',
  },

  // 批量设置直属领导
  // employeeSettingLeader: { url: `${baseUrl}/employee/setting/leader`, method: 'post' },

  ///************************员工档案************************

  ///************************职务相关接口************************
  //职务分页查询
  getJobSettingPageAPi: { url: `${baseUrl}/post/page`, method: 'post' },
  //新建职务
  JobSettingCreateAPi: { url: `${baseUrl}/post/create`, method: 'post' },
  //   编辑职务
  JobSettingUpdateAPi: { url: `${baseUrl}/post/update`, method: 'post' },
  //   删除职务
  postRemove: { url: `${baseUrl}/post/remove`, method: 'delete' },
  ///************************职务相关接口************************

  ///************************职级相关接口************************
  //   职级分页查询
  postLevelPageAPI: { url: `${baseUrl}/postLevel/page`, method: 'post' },
  //   创建职级
  postLevelCreateAPI: { url: `${baseUrl}/postLevel/create`, method: 'post' },
  //   编辑职级
  postLevelUpdateAPI: { url: `${baseUrl}/postLevel/update`, method: 'post' },
  //   删除职级
  postLevelRemove: { url: `${baseUrl}/postLevel/remove`, method: 'delete' },
  ///************************职级相关接口************************

  ///************************我的档案************************
  //获取人员档案基础信息详情 with userID
  employeeArchivesDetailAPI: {
    url: `${baseUrl}/employee/archives/detail_with_userid`,
    method: 'get',
  },
  //   工作经历
  employeeworkHistoryAPI: {
    url: `${baseUrl}/work_history/list_by_userid`,
    method: 'get',
  },
  //   教育经历
  employeeEducationAPI: {
    url: `${baseUrl}/education_info/list_by_userid`,
    method: 'get',
  },
  // 证书
  employeeCertificateAPI: {
    url: `${baseUrl}/certificate/list_by_userid`,
    method: 'get',
  },
  //   入职信息
  employeeOnboardingAPI: {
    url: `${baseUrl}/onboarding/detail_by_userid`,
    method: 'get',
  },
  //   劳动合同
  employeeLaborContractAPI: {
    url: `${baseUrl}/laborContract/list_by_userid`,
    method: 'get',
  },
  ///************************我的档案************************
};
