/*
 * @Descripttion: 公告相关接口
 * @Author: chenrui
 * @Date: 2024-01-30
 * @LastEditors: hehua
 * @LastEditTime: 2024-02-21 21:08:47
 */
const baseUrl = 'api/drsp_operation/notice'
export default {
    noticeCreate: { url: `${baseUrl}/create`, method: 'post' },
    noticePublish: { url: `${baseUrl}/publish`, method: 'post' },
    noticeUpdate: { url: `${baseUrl}/update`, method: 'post' },
    noticeDelete: { url: `${baseUrl}/delete`, method: 'post' },
    noticeDetail: { url: `${baseUrl}/detail`, method: 'get' },
    noticePage: { url: `${baseUrl}/page`, method: 'post' },
}