<template>
  <div class='empytLayout'>
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'EmpytLayout',
}
</script>
