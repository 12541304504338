<template>
  <svg :class="svgClass" aria-hidden="true">
    <use :xlink:href="iconName" />
  </svg>
</template>

<script>
// 引入本地的svg文件
const requireAll = requireContext => requireContext.keys().map(requireContext); // 定义一个加载目录的函数
const req = require.context('@/assets/svg', false, /\.svg$/);
requireAll(req); // 加载目录下的所有的 svg 文件
export default {
  name: 'SvgIcon',
  props: {
    name: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    }
  },
  computed: {
    iconName() {
      return `#icon-${this.name}`;
    },
    svgClass() {
      if (this.className) {
        return `svg-icon ${this.className}`;
      }

      return 'svg-icon';
    }
  }
};
</script>

<style scoped>
.svg-icon {
  width: 1.3em;
  height: 1.3em;
  vertical-align: middle;
  fill: currentColor;
  overflow: hidden;
}
</style>
