<template>
  <div class="breadcrumb fbc">
    <el-breadcrumb class="app-breadcrumb" separator="|">
      <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
        <span
            class="no-redirect"
            @click="onClickTitle(item, index)"
            :style="{
              color: (item.redirect === 'noredirect' || index == levelList.length - 1)
              ? '#232323' : '#999999'
            }">
          {{ item.meta.title }}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="fullScreen fc" @click="enterFullScreen" v-if="!isShowScreen">
      <img src="@/assets/imgs/layout/fullScreen.png" alt="" srcset="">
      <p style="width: 30px;">全屏</p>
    </div>
    <div class="fullScreen fc" @click="exitFullScreen" v-else>
      <img src="@/assets/imgs/layout/fullScreen.png" alt="" srcset="">
      <p style="width: 60px;">退出全屏</p>
    </div>
  </div>
</template>

<script>
import pathToRegexp from 'path-to-regexp'

export default {
  data() {
    return {
      levelList: null,
      isShowScreen: false
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  created() {
    this.getBreadcrumb()
  },
  mounted() {
    this.isShowScreen = this.checkFull()
    window.addEventListener('fullscreenchange', () => {
      if (!this.checkFull()) {
        this.isShowScreen = false
        this.$store.commit('setMenuCollapse', this.isShowScreen)
      }
    })
  },
  methods: {
    getBreadcrumb() {
      // 筛选出除了一级以外的标题，一级标题是系统设置不显示
      let matched = this.$route.matched.filter((item) => item.name).filter((item, index) => index !== 0)
      this.levelList = matched.filter((item) => item.meta && item.meta.title)
    },
    pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      const { params } = this.$route
      var toPath = pathToRegexp.compile(path)
      return toPath(params)
    },
    handleLink(item) {
      const { redirect, path } = item
      if (redirect) {
        this.$router.push(redirect)
        return
      }
      this.$router.push(this.pathCompile(path))
    },
    //获取全屏信息
    checkFull() {
      let isFull = document.fullscreenElement ? true : false
      if (isFull === undefined || isFull === null) isFull = false
      return isFull
    },
    // 进入全屏
    enterFullScreen() {
      const container = document.documentElement
      if (container.requestFullscreen) {
        container.requestFullscreen()
      } else if (container.webkitRequestFullscreen) {
        container.webkitRequestFullscreen()
      } else if (container.mozRequestFullScreen) {
        container.mozRequestFullScreen()
      } else if (container.msRequestFullscreen) {
        container.msRequestFullscreen()
      }
      this.isShowScreen = true
      this.$store.commit('setMenuCollapse', this.isShowScreen)
    },
    // 退出全屏
    exitFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
      this.isShowScreen = false
      this.$store.commit('setMenuCollapse', this.isShowScreen)
    },
    /**
     * 点击面包屑
     */
    onClickTitle (data, index) {
      // 如果点击第一级别的 return
      if (!index) {
        return
      }
      if (data?.path) {
        // 点击当前页面 刷新
        if (this.$route.path === data?.path) {
          window.location.reload()
        } else {
          // 点击其他跳转
          this.$router.push(data?.path)
        }
      }
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.breadcrumb {
  padding: 16px 0;
}

.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  width: 100%;
  padding-left: 24px;
  font-size: 16px;
  font-family: PingFangSC-Bold;
  color: #000000;

  .no-redirect {
    color: #97a8be;
    font-family: PingFangSC-Bold;
    cursor: pointer;
  }
}

.fullScreen {
  cursor: pointer;
  margin-right: 20px;

  img {
    width: 24px;
  }

  p {
    margin-left: 4px;
    font-family: PingFangSC-Bold;
    font-size: 14px;
    color: #253044;
    line-height: 20px;
  }
}

::v-deep .el-breadcrumb__separator {
  position: relative;
  top: -1px;
}
</style>
