/*
 * @Descripttion: 服务商和客户的一些公共接口
 * @Author: hehua
 * @Date: 2024-06-26 15:24:21
 * @LastEditors: hehua
 * @LastEditTime: 2024-06-27 11:01:44
 */
const baseUrl = 'api/drsp_elf'
export default {
  // 获取服务商网点树
  getProviderTree: { url: `${baseUrl}/provider/child_tree`, method: 'get' },
  ///************************provider************************
  // 查询模块信息配置
  getProviderConfigModuleInfo: { url: `${baseUrl}/provider/config/module/info`, method: 'get' },
  // 查询模块信息配置 - 根据模块类型查询
  getConfigModuleInfoWithType: { url: `${baseUrl}/provider/config/module/info_with_type`, method: 'get' },
  // 配置模块信息
  setProviderConfigModule: { url: `${baseUrl}/provider/config/module/setting`, method: 'post' },

  ///************************customer************************
  //获取客户的定制化树形
  getCustomerExtTree: { url: `${baseUrl}/customer/ext/tree`, method: 'get' },
  // 服务商分发客户
  providerRelation: { url: `${baseUrl}/customer/relation`, method: 'post' },
  // 获取客户子列表
  getCustomerChildList: { url: `${baseUrl}/customer/child`, method: 'get' },
  //获取客户列表分页
  getCustomerPage: { url: `${baseUrl}/customer/page`, method: 'post' },
  //获取客户树
  getCustomerTree: { url: `${baseUrl}/customer/tree`, method: 'get' },
}