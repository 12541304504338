/*
 * @Descripttion: foundation通用接口
 * @Author: hehua
 * @Date: 2024-01-27 15:00:53
 * @LastEditors: hehua
 * @LastEditTime: 2024-02-21 21:09:05
 */
const baseUrl = 'api/drsp_operation';
const smsBaseUrl = 'api/drsp_operation/sms';
export default {
  // 获取下级地区列表
  getGeoAreaList: { url: `${baseUrl}/area/children`, method: 'get' },
  // 上传文件
  fileUpload: { url: `${baseUrl}/file/create`, method: 'get' },
  // 词典枚举列表
  getDictionaryValueList: {
    url: `${baseUrl}/dictionary/value/list`,
    method: 'get',
  },
  //20240221新增--
  // 发送短信验证码
  smsSend: { url: `${smsBaseUrl}/send/verify_code`, method: 'post' },
  // 验证短信验证码
  smsCheck: { url: `${smsBaseUrl}/check/verify_code`, method: 'post' },
};
