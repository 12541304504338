<template>
  <!-- 二次确认弹窗 -->
  <el-dialog :close-on-click-modal="false" width="440px" class="delete-confirm" :visible.sync="confirmDialigShow"
    :show-close="false">
    <div class="top">
      <img class="danger" :src="require('./danger.png')">
      <div class="title">{{ title }}</div>
    </div>
    <div class="bottom">{{ content }}</div>
    <span slot="footer" class="dialog-footer">
      <el-button v-if="showCancelButton" size="small" @click="cancel">{{ cancelButtonText || '取消' }}</el-button>
      <el-button size="small" type="primary" @click="confirm">{{ confirmButtonText || '确定' }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '提示',
      content: '该数据删除将无法恢复，是否确认删除？',
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      showCancelButton: true,
      confirmDialigShow: true
    }
  },
  methods: {
    show(cb) {
      this.showFlag = true
      typeof cb === "function" && cb.call(this, this)
      return new Promise((resolve, reject) => {
        this.reject = reject
        this.resolve = resolve
      })
    },
    cancel() {
      this.reject("cancel")
      this.hide()
    },
    confirm() {
      this.resolve("confirm")
      this.hide()
    },
    hide() {
      this.showFlag = false
      document.body.removeChild(this.$el)
      this.$destroy()
    }
  }
}
</script>
<style lang='scss' scoped>
.delete-confirm {
  // width: 440px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  .top {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .danger {
      width: 30px;
      height: 24px;
      margin-right: 12px;
    }

    .title {
      font-family: PingFangSC-Bold;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 24px;
    }
  }

  .bottom {
    font-size: 14px;
    font-weight: 400;
    color: #324457;
    line-height: 22px;
    margin-top: 20px;
  }

  ::v-deep .el-dialog__header {
    display: none;
  }

  ::v-deep {
    .el-dialog {
      margin-top: 20vh !important;
    }

    .el-dialog__body {
      padding: 24px;
    }

    .el-dialog__footer {
      border: none;
      line-height: 0;
      padding: 8px 24px 24px 0 !important;
    }
  }
}
</style>