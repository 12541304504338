/*
 * @Descripttion: 路由映射表
 * @Author: hehua
 * @Date: 2024-03-20 16:47:18
 * @LastEditors: hehua
 * @LastEditTime: 2024-06-26 17:41:54
 */
const asyncRouterMap = {
  EmpytLayout: () => import('@/layout/empytLayout.vue'),
  Layout: () => import('@/layout/mainLayout.vue'),
  RedMsg: () => import('@/views/message/redMsg/index.vue'),
  //***************************  设置  ***************************/
  //设置-基本信息
  UserInfo: () => import('@/views/setting/userInfo/index.vue'),
  //设置-全部应用
  SettingAppSquare: () => import('@/views/setting/appSquare/index.vue'),
  //设置-组织机构
  OrgManage: () => import('@/views/setting/organization/orgManage.vue'),
  DepartmentManage: () => import('@/views/setting/organization/departmentManage.vue'),
  PersonManage: () => import('@/views/setting/organization/personManage.vue'),
  PostManage: () => import('@/views/setting/organization/postManage.vue'),
  //设置-账号权限
  RoleManage: () => import('@/views/setting/accountPower/roleManage.vue'),
  //系统设置—角色授权
  employeeRoleManage: () => import('@/views/employeeProfile/roleManage/index.vue'),
  AccoutManage: () => import('@/views/setting/accountPower/accoutManage.vue'),
  //设置-协同管理
  CustomeList: () => import('@/views/setting/collaborationManage/customeList/index'),
  CustomeDetail: () => import('@/views/setting/collaborationManage/customeList/customeDetail'),
  ServiceProviderList: () => import('@/views/setting/collaborationManage/serviceProviderList'),
  ServiceProviderDetail: () => import('@/views/setting/collaborationManage/serviceProviderList/serviceProviderDetail'),
  //设置-自定义内容
  Notice: () => import('@/views/setting/custom/notice.vue'),
  //设置-权限管理
  SysMenu: () => import('@/views/setting/powerMannage/sysMenu.vue'),
  PowerGroupSet: () => import('@/views/setting/powerMannage/powerGroupSet.vue'),
  //设置-数据字典
  DataDict: () => import('@/views/setting/dataDict/index.vue'),
  //设置-系统日志
  LoginLog: () => import('@/views/setting/systemLog/loginLog.vue'),
  OperationLog: () => import('@/views/setting/systemLog/operationLog.vue'),
  //设置-系统设置
  MsgSetting: () => import('@/views/setting/systemSetting/msgSetting.vue'),
  //设置-服务设置
  ServeSettingOutNode: () => import('@/views/setting/serveSetting/outNode/index.vue'),
  ServeSettingRelateConfig: () => import('@/views/setting/serveSetting/relateConfig/index.vue'),
  ServeSettingFilingApplication: () => import('@/views/setting/serveSetting/filingApplication/index.vue'),
  ServeSettingFilingDetail: () => import('@/views/setting/serveSetting/filingApplication/filingDetail.vue'),
  ServeSettingTimeSetting: () => import('@/views/setting/serveSetting/timeSetting/index.vue'),

  //***************************  应用  ***************************/
  //设置-应用管理
  SquareManage: () => import('@/views/squareCenter/squareManage/index.vue'),
  //设置-应用菜单
  SquarePcMenu: () => import('@/views/squareCenter/squareMenu/pcMenu.vue'),
  SquareH5Menu: () => import('@/views/squareCenter/squareMenu/h5Menu.vue'),

  //***************************  租户  ***************************/
  //租户-租户管理
  TenantManage: () => import('@/views/tenantCenter/tenantManage/index.vue'),
  //租户-应用授权
  SquareEmpower: () => import('@/views/tenantCenter/tenantManage/squareEmpower.vue'),
  //租户-开通管理
  OpenManage: () => import('@/views/tenantCenter/openManage/index.vue'),
  //租户-租户协同
  Collaboration: () => import('@/views/tenantCenter/collaboration/index.vue'),
  //租户-租户协同 - 租户详情
  TenantDetail: () => import('@/views/tenantCenter/collaboration/tenantDetail.vue'),

  //***************************  基础应用  ***************************/
  //智能工单 - 工单统计
  // WorkOrder: () => import('@/views/serve/cleverWorkOrder/analyse/workOrder/index.vue'),

  // 员工档案
  // 我的档案页面
  MyProfile: () => import('@/views/employeeProfile/MyProfile.vue'),

  // 员工花名册
  StaffRoster: () => import('@/views/employeeProfile/StaffRoster.vue'),

  // 员工档案
  StaffProfile: () => import('@/views/employeeProfile/StaffProfile/index.vue'),

  // 员工档案完善
  StaffProfileEdit: () => import('@/views/employeeProfile/StaffProfile/edit.vue'),

  // 合同管理
  ContractManagement: () => import('@/views/employeeProfile/ContractManagement.vue'),

  // 基础设置
  // BasicSettings: () => import('@/views/employeeProfile/BasicSettings/DepartmentSettings.vue'),

  // 部门设置
  DepartmentSettings: () => import('@/views/employeeProfile/BasicSettings/DepartmentSettings.vue'),

  // 岗位设置
  PositionSetting: () => import('@/views/employeeProfile/BasicSettings/PositionSetting/index.vue'),

  // 职务设定
  JobSetting: () => import('@/views/employeeProfile/BasicSettings/JobSetting/index.vue'),

  // 职级设定
  JobLevelSetting: () => import('@/views/employeeProfile/BasicSettings/JobLevelSetting/index.vue'),

  // 数据字典
  DataDictionary: () => import('@/views/employeeProfile/BasicSettings/DataDictionary/index.vue')
}
export default asyncRouterMap
