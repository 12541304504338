<template>
  <div class="HeaderService" @click.stop>
    <div class="HeaderServiceBox">
      <div class="appItem-hover">
        <div class="appItem-scene">
          <div class="sceneList">
            <div class="sceneList-title">场景</div>
            <div class="sceneList-item-list">
              <div class="sceneList-item" :class="{ 'sceneList-item-active': item.sceneCode == sceneCode }"
                v-for="(item, index) in actionList" :key="index" @click="changeScene(item)">
                {{ item.sceneCodeDesc }}
              </div>
            </div>

          </div>
        </div>
        <div class="appItem-menu">
          <div class="menu-item fc" @click="goAppDirect(item)" v-for="(item, index) in getModuleList()" :key="index">
            <img class="item-headImg" :src="item.icon" alt="">
            <div class="item-info">
              <p class="item-title row-1">{{ item.moduleName }}</p>
              <p class="item-description row-1">{{ item.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import storage from '@/store/storage'
export default {
  name: 'HeaderService',
  components: {},
  data() {
    return {
      actionList: [],
      actionListWidth: 0,
      sceneCode: 'BASE',
      moduleList: [],
    }
  },

  created() {
    this.functionModuleListTenantGroup()
  },
  methods: {
    async functionModuleListTenantGroup() {
      try {
        const res = await this.$httpServer.functionModuleListTenantGroup({ enable: true })
        if (res.code == 0) {
          this.actionList = res.data
          this.sceneCode = res.data[0].sceneCode
          this.getModuleList()
        }
      } catch (error) {
        console.log('error-->', error)
      }
    },
    getModuleList() {
      if (this.sceneCode) {
        const _arr = this.actionList.find(item => item.sceneCode == this.sceneCode)
        this.moduleList = _arr ? _arr.moduleList : []
        return this.moduleList
      } else {
        return []
      }

    },
    changeScene(item) {
      this.sceneCode = item.sceneCode
    },

    async goAppDirect(moduleInfo) {
      let instance = this.$loading({
        text: "应用信息拉取中",
        background: "rgba(0, 0, 0, 0.2)"
      });
      try {
        instance.close();
        storage.setLocalItem("moduleId", moduleInfo.moduleId);
        storage.setLocalItem("moduleName", moduleInfo.moduleName);
        this.$router.push(moduleInfo.path);
      } catch (error) {
        console.log('error: ', error);
        instance.close();
      }
    },
  }
}
</script>
<style lang='scss' scoped>
.HeaderService {
  position: absolute;
  top: 62px;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 999;
  border-top: 1px solid #f5f7fa;
  border-bottom: 1px #f5f7fa solid;
  overflow-x: hidden;
  // height: calc(100vh - 64px);
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);

  .HeaderServiceBox {
    background-color: #fff;
    width: 100%;
  }

  .appItem-hover {
    display: flex;
    height: 100%;
    width: 1140px;
    margin: 0 auto;
  }

  .appItem-scene {
    height: 408px;
    background: #FFFFFF;
    box-shadow: 8px 0px 18px -8px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: flex-end;

    .sceneList {
      width: 190px;
      padding-right: 20px;
      padding-top: 40px;
      box-sizing: border-box;
    }

    .sceneList-title {
      font-family: PingFangSC-Bold;
      font-size: 20px;
      color: #317DFF;
      line-height: 28px;
      position: relative;
      display: inline-block;
      margin-bottom: 24px;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: #317DFF;
        bottom: 0;
        left: 0;
      }
    }

    .sceneList-item-list {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 300px;
    }

    .sceneList-item {
      width: 170px;
      height: 44px;
      line-height: 44px;
      box-sizing: border-box;
      padding-left: 16px;
      background: #ffffff;
      border-radius: 4px;
      font-weight: 400;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.9);
      margin: 8px 0;
    }

    .sceneList-item-active {
      background: #DDEAFF;
      color: $theme;
      font-family: PingFangSC-Bold;
    }
  }

  .appItem-menu {
    flex: 1;
    padding-top: 24px;
    height: 408px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    padding-left: 42px;
    align-content: flex-start;

    .menu-item {
      margin: 16px 0;
      align-items: flex-start;
      padding-right: 48px;

      .item-headImg {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        object-fit: cover;
      }

      .item-info {
        width: 160px;
        margin-left: 12px;

        .item-title {
          font-family: PingFangSC-Bold;
          font-size: 14px;
          color: #000000;
          line-height: 20px;
        }

        .item-description {
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          line-height: 17px;
          padding-top: 4px;
        }
      }
    }
  }
}
</style>