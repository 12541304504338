import asyncRouterMap from '@/router/asyncRouterMap';
import storage from '@/store/storage';
import store from '@/store';
import constRoutes from '@/router/constRoutes';
import Vue from 'vue';

const permission = {
  state: {
    routes: storage.getLocalItem('routes') || null, // 获取的路由表
    buttonPermission: storage.getLocalItem('buttonPermission') || null, //权限表
    hasSetting: false,
    appRoutes: storage.getLocalItem('appRoutes') || [], // 应用路由表
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      storage.setLocalItem('routes', routes);
      state.routes = routes;
    },
    SET_APPROUTES: (state, routes) => {
      storage.setLocalItem('appRoutes', routes);
      state.appRoutes = routes;
    },
    SET_BUTTON: (state, buttonPermission) => {
      storage.setLocalItem('buttonPermission', buttonPermission);
      state.buttonPermission = buttonPermission;
    },
    SET_SETTING: (state, val = false) => {
      state.hasSetting = val;
    },
  },
  actions: {
    // 路由生成：在得到用户角色后第一时间调用
    generateRoutes({ commit, state }) {
      return new Promise((resolve) => {
        Vue.prototype.$httpServer.getUserAccessInfo().then((res) => {
          const { buttonList, moduleAccessTreeList } = res.data;
          commit('SET_BUTTON', buttonList);
          const moduleList = moduleAccessTreeList.filter(
            (item) =>
              (item.accessTreeList && item.accessTreeList.length) ||
              item.moduleCode == 'service'
          );
          moduleList.forEach((item, index) => {
            item.zIndex = 1;
            item.linkPath = item.path;
            item.moduleCode = 'Layout';
            item.children = item.accessTreeList;
            item.metaInfo = JSON.stringify({ title: item.moduleName });
            item.redirect =
              item.accessTreeList && item.accessTreeList.length > 0
                ? item.accessTreeList[0].linkPath
                : '/';
          });
          // if (store.state.user.role == 'CUSTOMER') {
          //   moduleList.unshift({
          //     moduleCode: 'home',
          //     moduleId: '10001',
          //     moduleName: '首页',
          //     path: '/home',
          //     redirect: '/home/workerPlace',
          //   });
          // }
          // console.log('moduleList', moduleList)
          commit('SET_ROUTES', JSON.parse(JSON.stringify(moduleList)));
          const _routes = getRoutes([...moduleList, ...state.appRoutes]);
          commit('SET_SETTING', true);
          resolve(_routes);
        });
      });
    },
    // 应用路由生成：在用户点击进入服务后调用
    setAppRoutes({ commit, state }, moduleInfo) {
      return new Promise((resolve, reject) => {
        const { moduleCode, moduleName, moduleId } = moduleInfo;
        Vue.prototype.$httpServer.accessModuleTree({ moduleId }).then((res) => {
          if (res.code == 0) {
            if (!res.data.length) {
              reject();
              return Vue.prototype.$message.info(
                '您没有该应用的相关权限，请联系管理员开通'
              );
            }
            const hasAppRoute = state.appRoutes.find(
              (item) => item.moduleId == moduleId
            );
            if (hasAppRoute) resolve({ currentAppRoute: res.data });
            const appModuleList = [
              {
                linkPath: '/' + moduleCode,
                moduleCode: 'Layout',
                children: res.data,
                moduleId: moduleId,
                moduleName: moduleName,
                name: moduleCode,
                redirect: res.data[0].linkPath,
                isApp: true,
                zIndex: 1,
              },
            ];
            let sysRoutes = [];
            if (localStorage.getItem('routes')) {
              sysRoutes = JSON.parse(localStorage.getItem('routes'));
            }
            commit('SET_APPROUTES', JSON.parse(JSON.stringify(appModuleList)));
            const moduleList = [...sysRoutes, ...appModuleList];
            const _routes = getRoutes(moduleList);
            resolve({
              routes: _routes,
              currentAppRoute: res.data,
            });
          }
        });
      });
    },
  },
  getters: {},
};

export function getRoutes(routes) {
  const newRoutes = JSON.parse(JSON.stringify([...routes]));
  const _routes = filterAsyncRoutes(newRoutes);
  //超管会将首页重定向更换为routes第一个path（基本为租户管理），其他权限组会跳去应用广场
  let redirect;
  const isCustomer = store.state.user.role == 'CUSTOMER';
  if (isCustomer) redirect = '/home/appSquare';
  redirect = routes && routes.length ? routes[0].redirect : '/home/appSquare';
  const _constRoutes = [
    {
      path: '/',
      redirect,
      component: () => import('@/layout/index.vue'),
      children: [...constRoutes, ..._routes],
    },
  ];
  return _constRoutes;
}

function isJsonString(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * 递归过滤AsyncRoutes路由表
 * @routes 带过滤的路由表，首次传入的就是AsyncRoutes
 * @roles  用户拥有角色
 */
export function filterAsyncRoutes(routes) {
  routes.forEach((item) => {
    item.name = item.linkPath
      ? item.linkPath.replace(/\//g, '')
      : item.moduleCode;
    item.component = asyncRouterMap[item.accessCode || item.moduleCode];
    item.path = item.path || item.linkPath;
    item.metaInfo = item.metaInfo
      ? item.metaInfo.replace(/'/g, '"')
      : `{"title":"${item.accessName || item.moduleName}"}`;
    item.meta = isJsonString(item.metaInfo) ? JSON.parse(item.metaInfo) : {};
    item.meta.permit = []; //容纳权限按钮组
    if (item.children && item.children.length > 0) {
      //塞入按钮
      const permitList = item.children.filter(
        (cItem) => cItem.accessType === 'BUTTON'
      );
      permitList.forEach((perItem) => {
        item.meta.permit.push(perItem.accessCode);
      });
      //重新生成children
      item.children = item.children.filter(
        (cItem) => cItem.accessType !== 'BUTTON'
      );
      if (item.children.length) {
        item.redirect = item.children[0].linkPath;
        filterAsyncRoutes(item.children);
      } else {
        item.children = null;
      }
    } else {
      item.children = null;
    }
  });
  return routes;
}

export default permission;
