import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { btnPermit, pathPermit } from "@/utils"
import '@/utils/commonSubModule.js'
import '@/utils/element-ui.js'
import "@/assets/css/variables.scss"
import '@/assets/css/public.css'
import '@/assets/css/variables.scss'
import '@/assets/css/ys-theme/index.scss'
import '@/assets/css/index.scss'
import { axiosRequestUse, axiosResponseUse } from '@/utils/axiosConfig.js' // axios配置
import svgIcon from '@/components/svgIcon' // svg组件
// 路由权限拦截
import './router/permission'
import "./utils/extend"
axiosRequestUse() // axios请求拦截器
axiosResponseUse() // axios响应拦截器
Vue.component('svgIcon', svgIcon) // svg组件

Vue.config.productionTip = false
new Vue({
  router,
  store,
  beforeCreate() {
    Vue.prototype.$EventBus = this
    Vue.prototype.$btnPermit = btnPermit
    Vue.prototype.$pathPermit = pathPermit

  },
  render: h => h(App)
}).$mount('#app')
