/*
 * @Descripttion: 公共工具
 * @Author: hehua
 * @Date: 2024-02-02 14:31:46
 * @LastEditors: hehua
 * @LastEditTime: 2024-04-14 15:41:48
 */
import Vue from 'vue'
import router from '@/router'
export const getDictionaryValueList = (that, dictionaryCode) => {
  return new Promise((resolve, reject) => {
    that.$httpServer
      .getDictionaryValueList({ dictionaryCode })
      .then(res => {
        res.data.forEach(item => {
          item.data = item.enumValue;
          item.label = item.enumName;
        });
        resolve(res.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export function deepCopyWithFunctions(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }
  let clone = Array.isArray(obj) ? [] : {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        clone[key] = deepCopyWithFunctions(obj[key]);
      } else if (typeof obj[key] === "function") {
        clone[key] = obj[key]; // 将函数复制到新对象中
      } else {
        clone[key] = obj[key];
      }
    }
  }
  return clone;
}
/**
 * 
 * @param {string} key 权限按钮key
 */
export const btnPermit = (key) => {
  const permitList = router.history.current.meta.permit || []
  return permitList.includes(key)
}
/**
 * 
 * @param {string} key 权限页面key
 */
export const pathPermit = (targetPath, tree) => {
  if (!tree) tree = JSON.parse(localStorage.getItem('routes'))
  for (let node of tree) {
    if (node.linkPath === targetPath) return true
    if (node.children && Array.isArray(node.children)) {
      if (pathPermit(targetPath, node.children)) return true
    }
  }
  return false;
}

// 判断是否是空值
export function isEmpty(value) {
  return (
    value === '' ||
    value === null ||
    value === undefined ||
    (typeof value === 'object' && Object.keys(value).length === 0)
  );
}
