/*
 * @Descripttion: foundation通用接口
 * @Author: hehua
 * @Date:  2024-01-30 13:51:59
 * @LastEditors: hehua
 * @LastEditTime: 2024-05-25 16:32:56
 */
const baseUrl = 'api/drsp_operation/function_module'
export default {
  // 新增功能模块
  functionModuleCreate: { url: `${baseUrl}/create`, method: 'post' },
  // 修改启用状态
  functionModuleEnableSwitch: { url: `${baseUrl}/enable/switch`, method: 'post' },
  // 查看功能模块信息
  functionModuleInfo: { url: `${baseUrl}/info`, method: 'get' },
  // 修改功能模块
  functionModuleModify: { url: `${baseUrl}/modify`, method: 'post' },
  // 查询功能模块分组列表
  functionModuleListGroup: { url: `${baseUrl}/list_group`, method: 'get' },
  // 查询功能模块分组列表 - 查询服务下拉列表
  functionModuleListTenantGroup: { url: `${baseUrl}/tenant/list_group`, method: 'get' },

  // 租户功能授权
  functionModuleTenantGrant: { url: `${baseUrl}/tenant/grant`, method: 'post' },
  // 查询租户功能模块列表
  functionModuleTenantList: { url: `${baseUrl}/tenant/list`, method: 'get' },
  // 租户功能修改
  functionModuleTenantModify: { url: `${baseUrl}/tenant/modify`, method: 'post' },
  // 租户功能移除
  functionModuleTenantRemove: { url: `${baseUrl}/tenant/remove`, method: 'post' },

  //0221添加
  //根据应用模块ID获取定时任务信息
  functionModuleQueryTimedTask: { url: `${baseUrl}/query/timed_task`, method: 'get' },
  //设置应用定时任务
  functionModuleSettingTimedTask: { url: `${baseUrl}/setting/timed_task`, method: 'post' },

  //0226添加
  //查看租户功能模块信息
  functionModuleTenantInfo: { url: `${baseUrl}/tenant/info`, method: 'get' },
  //租户功能激活
  functionModuleTenantActivate: { url: `${baseUrl}/tenant/activate`, method: 'post' },

}