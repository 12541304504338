<template>
  <div class="menus-wrap" :class="{ 'menus-wrap-active': isCollapse }">
    <el-menu class="sidebar-menu" :router="false" :show-timeout="200" :collapse="isCollapse" background-color="#001629"
      text-color="#B7C0CD" mode="vertical" :unique-opened="true" :default-active="$route.path"
      :collapse-transition="false">
      <div class="menu-title fbc">
        <p class="title" v-if="!isCollapse">{{ route.title || '--' }}</p>
        <div class="collapsebtn fcc" @click="changeIsCollapse()">
          <i :class="[isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold']"></i>
        </div>
      </div>
      <template v-for="(item, index) in route.list">
        <template v-if="getMenuMoreShow(item)">
          <el-submenu :index="item.path">
            <template slot="title">
              <svg-icon v-if="childrenHasPath(item.children, $route.path)" :name="`${item.meta.icon}_s`"
                class="menuIcon"></svg-icon>
              <svg-icon v-if="!childrenHasPath(item.children, $route.path)" :name="`${item.meta.icon}_n`"
                class="menuIcon"></svg-icon>
              <span class="menuSpan">{{ item.meta.title }}</span>
            </template>
            <template v-for="cItem in item.children">
              <el-menu-item v-if="!cItem.meta.hidden" :index="cItem.path" :key="cItem.path" @click="goPath(cItem)">
                {{ cItem.meta.title }}
              </el-menu-item>
            </template>

          </el-submenu>
        </template>
        <template v-if="getMenuShow(item)">
          <el-menu-item v-if="!item.meta.hidden" :index="item.path" :key="item.path" @click="goPath(item)">
            <svg-icon v-if="$route.path == item.path" :name="`${item.meta.icon}_s`" class="menuIcon"></svg-icon>
            <svg-icon v-if="$route.path != item.path" :name="`${item.meta.icon}_n`" class="menuIcon"></svg-icon>
            <span class="menuSpan" slot="title">{{ item.meta.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>
<script>
export default {
  name: "settingMenu",
  components: {},
  props: {
    route: {
      type: Object,
      default: () => {
        return {
          title: "",
          list: []
        };
      }
    }
  },
  data() {
    return {
    };
  },
  computed: {
    isCollapse() {
      return this.$store.state.layout.isCollapse
    }
  },
  methods: {
    goPath(item) {
      if (item.path === this.$route.path) return false;
      this.$router.push(item.path);
    },
    childrenHasPath(children, path) {
      return children.map(x => {
        return x.path
      }).includes(path)
    },
    getMenuMoreShow(item) {
      return item.children && item.children.length
    },
    getMenuShow(item) {
      return !item.children
    },
    changeIsCollapse() {
      this.$store.commit('setMenuCollapse', !this.isCollapse)
    }
  }
};
</script>
<style lang='scss' scoped>
.menus-wrap {
  width: 100%;
  box-sizing: border-box;
  // padding: 0 8px;
  position: relative;
  // padding-top: 10px;
  background: #001629;
  overflow: auto;
  border-right: none;
  width: 200px;
  max-width: 200px;
  transition: all 0.3s;

  .collapsebtn {
    width: 64px;
    height: 64px;
    cursor: pointer;
  }
}

.menus-wrap-active {
  width: 64px;
  min-width: 64px;
  max-width: 64px;
}

::v-deep .el-menu {
  border-right: none;
}

.sidebar-menu {
  // height: calc(100vh - 52px);
  // overflow-y: auto;
  // background-color: rgb(0, 21, 41);
  // // width: 180px !important;
  // width: 100%;
  // border-right: none;
  // box-shadow: 2px 0 6px rgba(0, 21, 41, .35);
  // transition: all .3s;

  .menu-title {
    font-size: 16px;

    .title {
      width: 100px;
      min-width: 100px;
      transition: none;
      padding-left: 16px;
      font-size: 17px;
      font-family: PingFangSC-Bold;
      color: #ffffff;
      line-height: 24px;
    }

    i {
      color: #909399;
      font-size: 18px;
      font-weight: 200;
    }
  }
}

.menus-wrap:not(.menus-wrap-active) {
  ::v-deep .el-menu-item {
    background-color: #317dff;
    border-radius: 4px;
    min-width: auto;
    margin: 3px 8px;
    box-sizing: border-box;
    height: 40px;
    line-height: 37px;
  }

  ::v-deep .el-menu-item.is-active {
    background-color: #0a2a47 !important;
    color: #fff;
  }

  ::v-deep .el-submenu__title {
    margin: 0 0px;
  }
}

.menuIcon {
  width: 24px !important;
  height: 24px !important;
}

.menuSpan {
  font-size: 14px;
  font-family: PingFangSC-Bold;
  line-height: 20px;
  margin-left: 8px;
}

.el-menu--inline {
  .el-menu-item {
    height: 40px;
    line-height: 40px;
    padding-left: 43px !important;
  }
}

::v-deep {
  .el-submenu__title {
    height: 40px;
    line-height: 35px !important;

    .menuSpan {
      font-weight: 400;
    }
  }

  .el-submenu.is-active .el-submenu__title {
    .menuSpan {
      font-family: PingFangSC-Bold;
      color: #fff;
    }
  }

  .el-menu-item {
    height: 40px;
    line-height: 35px;
    padding-left: 12px !important;
  }
}
</style>