/*
 * @Descripttion: 消息页面的菜单
 * @Date: 2024-01-24 11:32:44
 * @LastEditors: hehua
 * @LastEditTime: 2024-02-22 13:50:46
 */
const messageMenuRoutes = [
  {
    path: '/message/redMsg',
    name: 'RedMsg',
    component: () => import('@/views/message/redMsg/index.vue'),
    meta: { title: '阅读类消息', icon: 'redMsg', roles: ['ADMIN', 'OPERATION', 'CUSTOMER'] }
  },
]
export default messageMenuRoutes