<template>
  <div class='layout'>
    <div class="layout-warp">
      <LayoutMenu :route="currentRoutes" class="settingMenu"></LayoutMenu>
      <div class="content-wrap">
        <div class="content-wrap-scroll">
          <Breadcrumb v-if="!hideBreadcrumb"></Breadcrumb>
          <router-view class="content-wrap-view" :class="{ 'noMargin': hideBreadcrumb }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LayoutHeader from "@/layout/header/AdminHeader.vue"
import LayoutMenu from "./LayoutMenu"
import Breadcrumb from "./breadcrumb"
import messageMenuRoutes from "@/router/messageMenu.js"
import userMenuRoutes from "@/router/userMenu.js"


function isJsonString(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}
export default {
  name: 'layout',
  components: { LayoutHeader, LayoutMenu, Breadcrumb },
  data() {
    return {
      realName: '用户',
      isCollapse: false,
      backgroundColor: '#001529',
      textColor: '#9A9DA7',
      menuActiveTextColor: '#2c6bff',
      currentRoutes: {},//当前的路由表
      routes: {
        message: {
          title: '消息中心',
          list: messageMenuRoutes
        },
        userCenter: {
          title: '个人中心',
          list: userMenuRoutes
        },
        // setting: {
        //   title: '设置',
        //   list: settingMenuRoutes
        // },
        // tenantCenter: {
        //   title: '租户',
        //   list: tenantCenterMenuRoutes
        // },
        // squareCenter: {
        //   title: '应用',
        //   list: squareCenterMenuRoutes
        // }
      },
      //需要隐藏面包屑的路由地址
      hideBreadcrumbPathList: [
        '/tenantCenter/tenantDetail',
        '/setting/collaborationManage/customeDetail',
        '/setting/collaborationManage/serviceProviderDetail',
        '/setting/serveSetting/filingApplication/filingDetail'
      ],
    }
  },
  computed: {
    hideBreadcrumb() {
      return this.hideBreadcrumbPathList.includes(this.$route.path)
    }
  },
  watch: {
    $route(newVal) {
      this.getCurrentRoutes(newVal)
    }
  },

  async created() {
    const _newRoutes = this.filterAsyncRoutes([
      ...this.$store.state.permission.routes,
      ...this.$store.state.permission.appRoutes,
    ])
    _newRoutes.forEach(item => {
      this.routes[item.name] = {
        title: item.moduleName,
        list: item.children,
      }
    })
    setTimeout(() => {
      this.getCurrentRoutes(this.$route)
    }, 0);
  },
  methods: {
    getCurrentRoutes(newVal) {
      const str = newVal.path
      const startIndex = 1;
      const endIndex = str.indexOf('/', startIndex)
      const result = str.substring(startIndex, endIndex)
      this.currentRoutes = this.routes[result]
    },
    filterAsyncRoutes(routes) {
      routes.forEach((item, index) => {
        item.name = item.linkPath ? item.linkPath.replace(/\//g, "") : item.moduleCode
        item.path = item.path || item.linkPath
        item.metaInfo = item.metaInfo ? item.metaInfo.replace(/'/g, '"') : `{"title":"${item.accessName || item.moduleName}"}`
        item.meta = isJsonString(item.metaInfo) ? JSON.parse(item.metaInfo) : {}
        if (item.children && item.children.length > 0) {
          item.children = item.children.filter(cItem => cItem.accessType !== 'BUTTON');
          if (item.children.length) {
            this.filterAsyncRoutes(item.children)
          } else {
            item.children = null
            item.accessCode == 'EmpytLayout' && setTimeout(() => { routes.splice(index, 1) }, 0)
          }
          this.$forceUpdate()
        } else {
          item.children = null
          item.accessCode == 'EmpytLayout' && setTimeout(() => { routes.splice(index, 1) }, 0)
          this.$forceUpdate()
        }
      })
      return routes
    }
  }
}
</script>
<style lang='scss' scoped>
.layout {
  overflow: hidden;

  .layout-warp {
    width: 100%;
    min-width: 1120px;
    height: calc(100vh - 64px);
    overflow-y: auto;
    display: flex;

    .content-wrap {
      flex: 1;
      box-sizing: border-box;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: #F0F7FF;
      display: flex;
      flex-direction: column;

      .content-wrap-scroll {
        // height: calc(100vh - 102px);
        overflow-y: auto;
      }

      .content-wrap-view {
        margin: 0 20px;
        overflow-y: auto;
      }

      .noMargin {
        margin: 0;
      }
    }
  }
}

.search-box-wrap .el-form-item,
.search-con .el-form-item {
  margin-right: 16px;
  margin-bottom: 16px;
}
</style>