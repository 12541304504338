/*
 * @Descripttion: 设置 - 账号权限
 * @Author: chenrui
 * @Date: 2024-01-30
 * @LastEditors: hehua
 * @LastEditTime: 2024-05-30 14:30:31
 */
const baseUrl = 'api/drsp_operation/role'
const userBaseUrl = 'api/drsp_operation/user'

export default {
	///************************角色管理************************
	//创建角色
	roleCreate: { url: `${baseUrl}/create`, method: 'post' },
	//删除角色
	roleDelete: { url: `${baseUrl}/delete`, method: 'get' },
	//获取角色详情
	roleDetail: { url: `${baseUrl}/detail`, method: 'get' },
	//获取所有的角色列表
	roleList: { url: `${baseUrl}/list`, method: 'get' },
	//编辑角色
	roleModify: { url: `${baseUrl}/modify`, method: 'post' },
	//分页查询角色
	rolePage: { url: `${baseUrl}/page`, method: 'get' },
	//启用状态切换
	roleStatusSwitch: { url: `${baseUrl}/status/switch`, method: 'get' },
	//20240221新增--
	//获取角色权限授权信息
	roleAccessGrantinfo: { url: `${baseUrl}/access/grant/info`, method: 'get' },
	//角色授权
	roleAccessGrant: { url: `${baseUrl}/access/grant`, method: 'post' },
	//保存或更新角色数据权限
	roleDataPermissionUpdate: { url: `${baseUrl}/data_permission/update`, method: 'post' },

	///************************账号管理************************
	//创建账号
	userCreate: { url: `${userBaseUrl}/create`, method: 'post' },
	//删除账号
	userDelete: { url: `${userBaseUrl}/delete`, method: 'get' },
	//获取账号详情
	userDetail: { url: `${userBaseUrl}/detail`, method: 'get' },
	//获取自己的用户信息
	userInfo: { url: `${userBaseUrl}/info`, method: 'get' },
	//查询用户列表
	userList: { url: `${userBaseUrl}/list`, method: 'post' },
	//用户登录
	userLogin: { url: `${userBaseUrl}/login`, method: 'post' },
	//用户退出登录
	userLogout: { url: `${userBaseUrl}/logout`, method: 'post' },
	//修改用户
	userModify: { url: `${userBaseUrl}/modify`, method: 'post' },
	//分页查询用户
	userPage: { url: `${userBaseUrl}/page`, method: 'get' },
	// 验证是否号是否注册
	userCheckPhone: { url: `${userBaseUrl}/check_phone`, method: 'post', },
	// 修改用户状态
	userUpdateStatus: { url: `${userBaseUrl}/update/status`, method: 'post', },
	// 查询用户消息提醒列表
	userMessageRemindList: { url: `${userBaseUrl}/message/remind/list`, method: 'get', },
	// 用户消息提醒状态切换
	userMessageRemindSwitch: { url: `${userBaseUrl}/message/remind/switch`, method: 'post', },

	//20240221新增--
	//修改用户手机号
	userModifyPhone: { url: `${userBaseUrl}/modify_phone`, method: 'post', },
	// 发送短信验证码
	passwordReset: { url: `${userBaseUrl}/password/reset`, method: 'post' },
	//设置用户密码
	userModifyPassword: { url: `${userBaseUrl}/modify_password`, method: 'post' },
	//设置内 - 重置密码
	userPasswordSetting: { url: `${userBaseUrl}/password/setting`, method: 'post' },
	//租户管理 - 进入租户 - 用户免验证登录 - 20240530新增
	userLoginAuthFree: { url: `${userBaseUrl}/login/auth_free`, method: 'post' },
	// 修改租户状态 - 20240223新增
	userEnableSwitch: { url: `${userBaseUrl}/enable/switch`, method: 'post', },
	//用户模块待办统计 -20240223新增
	userModuleTodoStat: { url: `${userBaseUrl}/module/todo/stat`, method: 'get', },
	//用户常用模块列表
	userModuleCommonList: { url: `${userBaseUrl}/module/common/list`, method: 'get', },
	//用户模块查看
	userModuleView: { url: `${userBaseUrl}/module/view`, method: 'get', },
}