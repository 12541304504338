/*
 * @Descripttion: 个人中心页面的菜单
 * @Author: hehua
 * @Date: 2024-01-24 13:30:37
 * @LastEditors: hehua
 * @LastEditTime: 2024-02-21 20:31:07
 */
const userMenuRoutes = [
  {
    path: '/userCenter/accout',
    name: 'Accout',
    component: () => import('@/views/userCenter/accout/index.vue'),
    meta: { title: '账号信息', icon: 'accoutInfo', roles: ['ADMIN', 'OPERATION', 'CUSTOMER'] }
  },
  {
    path: '/userCenter/remind',
    name: 'Remind',
    component: () => import('@/views/userCenter/remind/index.vue'),
    meta: { title: '提醒设置', icon: 'noticeSetting', roles: ['ADMIN', 'OPERATION', 'CUSTOMER'] }
  },
]
export default userMenuRoutes