/*
 * @Descripttion: 消息相关接口
 * @Author: chenrui
 * @Date: 2024-01-30
 * @LastEditors: hehua
 * @LastEditTime: 2024-02-28 16:29:46
 */
const baseUrl = 'api/drsp_operation/message'
export default {
  messageAccessList: { url: `${baseUrl}/access/list`, method: 'post' },
  messageAccessSetting: { url: `${baseUrl}/access/setting`, method: 'post' },
  messageRecordList: { url: `${baseUrl}/record/list`, method: 'post' },
  messageRecordPage: { url: `${baseUrl}/record/page`, method: 'post' },
  messageRecordModifyReadStatus: { url: `${baseUrl}/record/modify/read_status`, method: 'post' },
  messageRecordUnReadTotal: { url: `${baseUrl}/record/un_read/total`, method: 'get' },
  messageConnect: { url: `${baseUrl}/connect`, method: 'get' },
}