/*
 * @Descripttion: 日志相关接口
 * @Author: chenrui
 * @Date: 2024-02-01
 * @LastEditors: hehua
 * @LastEditTime: 2024-02-21 21:08:50
 */
const baseUrl = "api/drsp_operation/operationRecord";
export default {
  operationRecordPage: { url: `${baseUrl}/page`, method: "post" },
};
