import Vue from 'vue'

import {
  SaasMain,
  pageComponent,
  formContentComponent,
  contentLayerComponent,
  tableContentComponent,
  CodeEdit,
  Drawer

  // Editor
  // SearchBoardComponent,
  // ModuleTreeComponent,
} from '@evision/common-sub-module' // 公共子包
import '@evision/common-sub-module/dist/index.css' // 前端包
import { apiData } from '@/api' // 请求接口配置

Vue.component('page', pageComponent); // 分页组件
Vue.component('formContent', formContentComponent); // 表单组件
Vue.component('contentLayer', contentLayerComponent); // 弹窗组件
Vue.component('tableContent', tableContentComponent); // 表格组件
Vue.component('codeEdit', CodeEdit); // 代码编辑器组件
Vue.component('drawer', Drawer); // 抽屉组件
// Vue.component('drawer', Editor); // 富文本框组件
// Vue.component('searchBoard', SearchBoardComponent);
// Vue.component('moduleTree', ModuleTreeComponent);

// 公共子包加载
const config = {
  env: process.env.NODE_ENV, // 环境
  apiData // 接口列表
};
const vm = new SaasMain(config);
vm.init(Vue);
