/*
 * @Descripttion: 超管 - 租户接口
 * @Author: hehua
 * @Date: 2024-01-29 10:56:19
 * @LastEditors: hehua
 * @LastEditTime: 2024-05-28 14:05:12
 */
const baseUrl = 'api/drsp_operation/tenant'
const cooperateUrl = 'api/drsp_operation/tenant_cooperate'
export default {
  // 修改租户状态
  tenantModifyStatus: { url: `${baseUrl}/enable/switch`, method: 'post', },
  // 新增租户
  tenantCreate: { url: `${baseUrl}/create`, method: 'post', },
  // 删除租户
  tenantDelete: { url: `${baseUrl}/delete`, method: 'get', },
  // 查看租户信息
  tenantInfo: { url: `${baseUrl}/info`, method: 'get', },
  // 查看租户详情(基本信息使用)
  tenantDetail: { url: `${baseUrl}/detail`, method: 'get', },
  // 修改租户
  tenantModify: { url: `${baseUrl}/modify`, method: 'post', },
  // 修改租户 -2
  tenantBaseInfoModify: { url: `${baseUrl}/base_info/modify`, method: 'post', },
  // 查询租户分页
  tenantPage: { url: `${baseUrl}/page`, method: 'post', },
  // 查询租户模块记录列表
  tenantModuleRecordList: { url: `${baseUrl}/module/record/page`, method: 'post', },
  // 租户管理员切换
  tenantAdminUserSwitch: { url: `${baseUrl}/admin_user/switch`, method: 'post', },
  // 租户交接记录
  tenantTransitionRecord: { url: `${baseUrl}/transition/record`, method: 'get', },

  //20240222新增--
  //用户详情 修改租户logo
  tenantModifyLogo: { url: `${baseUrl}/modify_logo`, method: 'post' },

  //20240528新增--
  //租户协同 - 查询租户协同列表
  getTenanCooperatePage: { url: `${cooperateUrl}/page`, method: 'post' },
  //租户协同 - 租户基本信息 - 查询租户客户商列表
  getTenantCustomerPage: { url: `${cooperateUrl}/cooperate_page`, method: 'post' },
  //租户协同 - 租户基本信息 - 查看协同租户信息
  getTenantCustomerInfo: { url: `${cooperateUrl}/info`, method: 'get' },
  //租户协同 - 租户基本信息 - 根据租户ID分页查询协同租户
  getTenantCustomerForTenantPage: { url: `${cooperateUrl}/page_with_tenant_id`, method: 'post' },
  //租户协同 - 租户基本信息 - 添加租户协同
  getTenantCustomerCreate: { url: `${cooperateUrl}/create`, method: 'post' },
}