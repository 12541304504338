<template>
  <div class="headerAvatar">
    <el-dropdown trigger="click" ref="dropdown">
      <div class="header-avatar fc" style="cursor: pointer">
        <el-image
          class="avatar"
          size="small"
          shape="circle"
          :src="userInfo.avatarUrl"
        />
        <span class="name row-1">{{ userInfo.realName }}</span>
        <img
          class="arrow"
          src="@/assets/imgs/layout/arrow.png"
          alt=""
          srcset=""
        />
      </div>
      <el-dropdown-menu slot="dropdown" custom-class="dropdownMenu">
        <el-container class="sort-container">
          <el-main class="sort-main">
            <div class="sort-main-user fc">
              <el-avatar
                class="user-avatar"
                size="small"
                shape="circle"
                :src="userInfo.avatarUrl"
              />
              <div>
                <p class="user-name row-1">{{ userInfo.realName }}</p>
                <p class="user-role row-1">
                  {{
                    userInfo.roles && userInfo.roles.length
                      ? userInfo.roles[0].roleName
                      : '--'
                  }}
                </p>
                <p class="user-role row-1" v-if="userInfo.positionName">
                  {{ userInfo.positionName }}
                </p>
              </div>
            </div>
            <div class="sort-main-info">
              <div class="sort-main-info-item">
                <p class="sort-main-info-item-label">机构</p>
                <p class="sort-main-info-item-role row-2">
                  {{ userInfo.orgName || '--' }}
                </p>
              </div>
              <div class="sort-main-info-item">
                <p class="sort-main-info-item-label">部门</p>
                <p class="sort-main-info-item-role">
                  <span
                    v-if="
                      userInfo?.departmentList &&
                      userInfo?.departmentList?.length
                    "
                  >
                    <span
                      v-for="(dep, index) in userInfo?.departmentList"
                      :key="dep?.departmentId"
                    >
                      <span>{{ dep?.departmentName }}</span>
                      <el-tag size="mini" v-if="dep?.isManagerEmployee"
                        >主管</el-tag
                      >
                      <span
                        style="margin: 0 3px"
                        v-if="index != userInfo?.departmentList?.length - 1"
                        >/</span
                      >
                    </span>
                  </span>
                </p>
              </div>
              <div class="sort-main-info-item">
                <p class="sort-main-info-item-label">账号</p>
                <p class="sort-main-info-item-role">
                  {{ userInfo.account || '--' }}
                </p>
              </div>
            </div>
            <div class="sort-main-action fbc">
              <div
                class="fcc"
                @click="goPage('/userCenter/accout')"
                style="cursor: pointer"
              >
                <i class="el-icon-user-solid"></i>
                <p>个人中心</p>
              </div>
              <div class="fcc" @click="logout()" style="cursor: pointer">
                <i class="el-icon-s-tools"></i>
                <p>退出登录</p>
              </div>
            </div>
          </el-main>
        </el-container>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'HeaderAvatar',
  computed: {
    ...mapState({
      role: (state) => state.user.role,
      userInfo: (state) => state.user.userInfo,
    }),
  },
  methods: {
    goPage(path) {
      if (this.$route.path === path) return;
      this.$refs.dropdown.hide();
      this.$router.push(path);
    },
    logout() {
      localStorage.clear();
      let instance = this.$loading({
        text: '退出中',
        background: 'rgba(0, 0, 0, 0.2)',
      });
      setTimeout(() => {
        instance.close();
        this.$refs.dropdown.hide();
        this.$message.success('退出成功');
      }, 500);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-avatar {
  height: 52px;
  display: inline-flex;
  color: #222222;

  .avatar,
  .name {
    align-self: center;
  }

  .name {
    max-width: 150px;
  }

  .arrow {
    width: 14px;
    height: 14px;
    margin-left: 2px;
  }

  .avatar {
    margin-right: 8px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }
}

.dropdownMenu {
  padding: 0;
}

.el-dropdown-menu {
  border-radius: 10px;
  overflow: hidden;
}

.sort-container {
  background-image: url('@/assets/imgs/layout/userBg.png');
  background-position: center;
  // background-size: cover;
  background-repeat: no-repeat;
  width: 316px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 23px;

  .sort-main {
    border-radius: 3px;
    box-sizing: border-box;
    color: #333;
    padding: 0;
  }

  .sort-main-user {
    .user-avatar {
      width: 56px;
      height: 56px;
      object-fit: cover;
      border-radius: 50%;
    }

    .user-name {
      flex: 1;
      padding-left: 12px;
      width: 200px;
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Bold;
      color: #121315;
      line-height: 28px;
    }

    .user-role {
      max-width: 200px;
      font-weight: 400;
      font-size: 10px;
      color: #317dff;
      line-height: 14px;
      padding: 2px 4px;
      background: #c9ddff;
      border-radius: 4px;
      margin-left: 12px;
      display: inline-block;
      margin-top: 4px;
    }
  }

  .sort-main-info {
    padding-left: 10px;
    padding-top: 10px;

    .sort-main-info-item {
      margin: 10px 0;
      display: flex;

      .sort-main-info-item-label {
        padding-right: 32px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #757e89;
        line-height: 18px;
      }

      .sort-main-info-item-role {
        flex: 1;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #121315;
        line-height: 20px;
      }
    }
  }

  .sort-main-action {
    padding-top: 29px;
    margin: 0 auto;
    width: 200px;

    p {
      // width: 108px;
      height: 36px;
      cursor: pointer;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1e1e1e;
      line-height: 36px;
    }

    i {
      font-size: 14px;
      margin-right: 10px;
    }
  }
}

::v-deep .el-dropdown-menu {
  padding: 0;
}
</style>
