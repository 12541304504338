/*
 * @Descripttion: 正则
 * @Author: hehua
 * @Date: 2024-01-19 17:32:51
 * @LastEditors: hehua
 * @LastEditTime: 2024-05-31 19:08:51
 */
//全局通用的名称校验，仅限输入中英文、数字、符号(_-@())
export const nameRegex = /^[a-zA-Z0-9_\-\@()\u4e00-\u9fa5]+$/
//全局登录账号校验，仅限输入英文、数字、符号(_-@())
export const accoutRegex = /^[a-zA-Z0-9_\-\@()]+$/
//全局登陆手机号校验
export const phoneRegex = /^(1[3-9]\d{9}|19[0-9]{8})$/
//登陆密码正则
export const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).*$/
//统一社会信用代码正则
export const creditCodeRegx = /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/
//限制只能是数字
export const numCodeRegx = /^\d*$/
//限制链接类型
export const pathRegx = /^[a-zA-Z0-9\/]*$/
//限制邮箱类型
export const emailRegx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

//全局登录账号校验，仅限输入英文、数字、符号(_-@())/
export const pathRegx2 = /^[a-zA-Z0-9_\-\@()\/]+$/
export const regular_zw_yw_sz_fh_kg = /^[\u4e00-\u9fa5a-zA-Z0-9\p{P}\p{S}\s]+$/u; // 仅限制输入中文、英文、数字、所有符号、空格
export const regular_zw_yw_sz_fh = /^[\u4e00-\u9fa5a-zA-Z0-9\p{P}\p{S}]+$/u; // 仅限制输入中文、英文、数字、所有符号
export const regular_zw_yw_sz_fh2 = /^[\u4e00-\u9fa5a-zA-Z0-9.,;:'"?!@#$%^&*()_+=-\[\]{}<>\/\\|`~]+$/; // 仅限制输入中文、英文、数字、所有符号
export const regular_zw_yw_sz = /^[\u4e00-\u9fa5a-zA-Z0-9]+$/u; // 仅限制输入中文、英文、数字
export const regular_yw_sz_fh = /^[a-zA-Z0-9\p{P}]+$/u; // 仅限制输入英文、数字、符号
export const regular_yw_sz_fh2 = /^[a-zA-Z0-9.,;:'"?!@#$%^&*()_+=-\[\]{}<>\/\\|`~]+$/; // 仅限制输入英文、数字、符号
export const regular_yw_sz = /^[a-zA-Z0-9]+$/; // 仅限制输入英文、数字
export const regular_sz = /^[0-9]+$/; // 仅限输入数字
export const regular_yw_sz_kh_dg = /^[a-zA-Z0-9()\-]+$/; // 仅限输入英文数字+括号+短杠
export const regular_yw_sz_dg = /^[a-zA-Z0-9\-]+$/; // 仅限输入英文数字+括号+短杠
export const regular_ip = /^(\d{1,3}\.){3}\d{1,3}$/; // ip格式校验
export const regular_mobile = /^[1][3,4,5,7,8,9][0-9]{9}$/; // 手机号校验
export const identification = /^\d{17}(\d|X|x)$/ // 身份证号校验
export const str_rule1 = /^[\u4e00-\u9fa5a-zA-Z0-9_\-@()]+$/; //校验一个字符串是否仅包含中文字符、英文字母、数字、下划线（_）、短划线（-）、at 符号（@）以及英文圆括号（()）
export const str_rule2 = /^[a-zA-Z0-9_\-@()]+$/; // 校验一个字符串是否仅包含英文字母、数字、下划线（_）、短划线（-）、at 符号（@）以及英文圆括号（()）
export const str_rule3 = /^[\u4e00-\u9fa5a-zA-Z0-9_\-@()]{2,30}$/; //校验一个字符串是否2-30个字符且仅包含中文字符、英文字母、数字、下划线（_）、短划线（-）、at 符号（@）以及英文圆括号（()）
export const str_rule4 = /^[a-zA-Z0-9_\-@()]{4,64}$/; // 校验一个字符串是否4-64个字符且仅包含英文字母、数字、下划线（_）、短划线（-）、at 符号（@）以及英文圆括号（()）

export const validator = (regex, errText) => {
  return {
    validator: function (rule, value, callback) {
      if (regex.test(value) || !value) return callback()
      callback(new Error(errText))
    },
    trigger: "blur"
  }
}
const newNum = (num, newNum) => num === null ? newNum : num
//中英校验，大多是名称
export const nameValidator = (title, min, max, required = true) => [
  { required: required, message: `请输入${title}`, trigger: "blur" },
  validator(nameRegex, `${title}仅限输入中英文、数字、符号(_-@())`),
  { min: newNum(min, 4), max: newNum(max, 30), message: `${title}长度限制${newNum(min, 4)}~${newNum(max, 30)}个字符`, trigger: "blur" }
]
//英文相关校验，大多数英文相关code
export const codeValidator = (title, min, max, required = true) => [
  { required: required, message: `请输入${title}`, trigger: "blur" },
  validator(accoutRegex, `${title}仅限输入英文、数字、符号(_-@())`),
  { min: newNum(min, 4), max: newNum(max, 30), message: `${title}长度限制${newNum(min, 4)}~${newNum(max, 30)}个字符`, trigger: "blur" }
]
//密码相关校验
export const passwordValidator = (title, min, max, required = true) => [
  { required: required, message: `请输入${title}`, trigger: "blur" },
  validator(passwordRegex, `${title}必须包括英文字母、数字，长度限制${newNum(min, 8)}~${newNum(max, 30)}个字符`),
  { min: newNum(min, 8), max: newNum(max, 30), message: `${title}长度限制${newNum(min, 8)}~${newNum(max, 30)}个字符`, trigger: "blur" },
]
//手机号相关校验
export const phoneValidator = (title, required = true) => [
  { required: required, message: `请输入${title}`, trigger: "blur" },
  validator(phoneRegex, `请输入正确格式的${title}`),
]
//社会信用代码相关校验
export const creditCodeValidator = (title, required = true) => [
  { required: required, message: `请输入${title}`, trigger: "blur" },
  validator(creditCodeRegx, `请输入正确的${title}`),
]
//纯数字关校验
export const numValidator = (title, required = true) => [
  { required: required, message: `请输入${title}`, trigger: "blur" },
  {
    validator: function (rule, value, callback) {
      if (numCodeRegx.test(value) || !value) {
        if (parseFloat(value) < 0) {
          callback(new Error(`${title}应大于0`))
        } else {
          return callback()
        }
      } else {
        callback(new Error(`请输入正确的${title}`))
      }

    },
    trigger: "blur"
  },
]
//菜单链接校验

export const pathValidator = (title, min, max, required = true) => [
  { required: required, message: `请输入${title}`, trigger: "blur" },
  validator(pathRegx, `请输入正确的${title}`),
  { min: newNum(min, 8), max: newNum(max, 30), message: `${title}长度限制${newNum(min, 8)}~${newNum(max, 30)}个字符`, trigger: "blur" },
]

export const pathValidator2 = (title, min, max, required = true) => [
  { required: required, message: `请输入${title}`, trigger: "blur" },
  validator(pathRegx2, `请输入正确的${title}`),
  { min: newNum(min, 8), max: newNum(max, 30), message: `${title}长度限制${newNum(min, 8)}~${newNum(max, 30)}个字符`, trigger: "blur" },
]

//邮箱相关校验
export const emailValidator = (title, required = true) => [
  { required: required, message: `请输入${title}`, trigger: "blur" },
  validator(emailRegx, `请输入正确格式的${title}`),
]