/*
 * @Descripttion: 系统各个模块需要的菜单，这些模块菜单是不需要授权公开的
 * @Author: hehua
 * @Date: 2024-05-30 14:41:22
 * @LastEditors: hehua
 * @LastEditTime: 2024-05-30 14:41:39
 */
import messageMenuRoutes from "./messageMenu"
import userMenuRoutes from "./userMenu"
import EmpytLayout from "@/layout/empytLayout.vue"
import MainLayout from "@/layout/mainLayout.vue"

const constRoutes = [
  {
    path: '/home',
    redirect: '/home/appSquare',
    component: EmpytLayout,
    name: 'Home',
    children: [
      {
        path: '/home/appSquare',
        name: 'HomeAppSquare',
        meta: { title: '应用广场', roles: ['OPERATION', 'CUSTOMER'], },
        component: () => import('@/views/home/appSquare.vue')
      },
      {
        path: '/home/appDetail',
        name: 'HomeAppDetail',
        meta: { title: '应用详情', roles: ['OPERATION', 'CUSTOMER'], },
        component: () => import('@/views/home/appDetail.vue')
      },
      {
        path: '/home/workerPlace',
        name: 'HomeWorkerPlace',
        meta: { title: '工作台', roles: ['CUSTOMER'], },
        component: () => import('@/views/home/workerPlace.vue')
      },
      {
        path: '/home/noticePage',
        name: 'HomeNoticePage',
        meta: { title: '更多公告', roles: ['CUSTOMER'], },
        component: () => import('@/views/home/noticePage.vue')
      },
    ]
  },
  {
    path: '/serve',
    name: 'serve',
    component: () => import('@/views/serve/index.vue')
  },
  {
    path: '/message',
    redirect: '/message/redMsg',
    component: MainLayout,
    meta: { title: '消息中心' },
    children: [...messageMenuRoutes],
  },
  {
    path: '/userCenter',
    redirect: '/userCenter/accout',
    component: MainLayout,
    meta: { title: '个人中心' },
    children: [...userMenuRoutes],
  },
]
export default constRoutes