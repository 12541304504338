import { nameValidator, nameRegex, validator } from "@/utils/regex"

// 账号权限 - 角色管理 - 数据范围list
export const viewTypeList = [
  { label: '本级', data: 0 },
  { label: '本级及以下', data: 1 },
  { label: '个人', data: 2 },
]
// 账号权限 - 角色管理 - 列表表头
export const roleTableTitle = [
  {
    type: 'input',
    label: '角色名称',
    prop: 'roleName',
    fixed: true,
    colW: 120,
    showOverFlowTooltip: true
  },
  {
    type: 'input',
    label: '角色类型',
    prop: 'roleType',
    colW: 120,
    format: (row) => row.roleType === 'CUSTOM_ROLE' ? '自定义角色' : '系统角色'
  },
  {
    type: 'input',
    label: '角色描述',
    prop: 'description',
    colW: 150,
    showOverFlowTooltip: true
  },
  {
    type: 'input',
    label: '数据范围',
    prop: 'viewType',
    showOverFlowTooltip: true,
    colW: 100,
    format(row) {
      return keyToLabel(viewTypeList, row.viewType)
    },
  },
  {
    type: 'slot',
    label: '开启应用',
    prop: 'modules',
    cloW: 200
  }
]
// 账号权限 - 角色管理 - 添加角色表单
export const addFormTitle = [
  {
    type: 'input',
    label: '角色名称',
    prop: 'roleName',
    placeholder: '请输入角色名称',
    maxlength: 30,
    showWordLimit: true,
    width: '100%',
  },
  {
    type: 'view',
    label: '角色类型',
    prop: 'roleType',
    format: (row) => row.roleType === 'CUSTOM_ROLE' ? '自定义角色' : '系统角色'
  },
  {
    type: 'select',
    label: '数据范围',
    prop: 'viewType',
    placeholder: '请选择数据范围',
    options: viewTypeList,
    width: '100%',
  },
  {
    type: 'checkbox',
    disabled: false,
    label: '开启应用',
    prop: 'moduleIds',
    options: [],
    width: '100%',
  },
  {
    type: 'input',
    label: '角色描述',
    prop: 'description',
    placeholder: '请输入角色描述',
    maxlength: 120,
    showWordLimit: true,
    width: '100%',
  }
]
// 账号权限 - 角色管理 - 添加角色表单规则
export const addFornRules = {
  roleName: nameValidator('角色名称', 2, 30),
  roleType: [{ required: true, message: "", trigger: "blur" }],
  viewType: [{ required: true, message: "请选择数据范围", trigger: "blur" }],
}
// 账号权限 - 角色管理 - 角色详情表单
export const roleDetailTitle = [
  {
    type: 'view',
    label: '角色名称',
    prop: 'roleName',
    width: '100%'
  },
  {
    type: 'view',
    label: '角色类型',
    prop: 'roleType',
    format: (row) => row.roleType === 'CUSTOM_ROLE' ? '自定义角色' : '系统角色',
    width: '100%'
  },
  {
    type: 'view',
    label: '数据范围',
    prop: 'viewType',
    format(row) {
      return keyToLabel(viewTypeList, row.viewType)
    },
    width: '100%'
  },
  {
    type: 'slot',
    label: '开启应用',
    prop: 'modules',
    width: '100%'
  },
  {
    type: 'view',
    label: '角色描述',
    prop: 'description',
    width: '100%'
  }
]
// 账号权限 - 角色管理 - 权限分配详情
export const powerDetailFormTitle = [
  {
    type: 'select',
    label: '选择应用',
    prop: 'moduleId',
    width: '100%',
    options: [],
    eventName: 'moduleChange',
    clearable: false,
  },
  {
    type: 'slot',
    label: '开通功能',
    prop: 'action',
    width: '100%'
  },
  {
    type: 'slot',
    label: '开通数据',
    prop: 'data',
    width: '100%'
  },
]
// 账号权限 - 角色管理 - 权限分配表单
export const powerFormTitle = [
  {
    type: 'select',
    label: '选择应用',
    prop: 'moduleId',
    placeholder: '请选择应用',
    options: [],
    eventName: 'moduleChange',
    clearable: false,
  },
  {
    type: 'slot',
    label: '开通功能',
    prop: 'action',
    width: '100%'
  },
  {
    type: 'slot',
    label: '开通数据',
    prop: 'data',
    width: '100%'
  },
]

// 账号权限 - 账号管理 - 列表表头
export const accoutTableTitle = [
  { type: 'input', label: '姓名', colW: '150px', showOverFlowTooltip: true, prop: 'realName', },
  { type: 'input', label: '账号名称', prop: 'account', },
  { type: 'slot', label: '所属角色', prop: 'roles', colW: '300px' },
  { type: 'input', label: '手机号码', prop: 'contactTel', },
  { type: 'input', label: '所属机构', prop: 'orgName', colW: '150px', showOverFlowTooltip: true, },
  { type: 'input', label: '所属部门', prop: 'departmentName', },
  { type: 'slot', label: '状态', prop: 'status' },
  { type: 'slot', label: '是否启用', prop: 'enable', },
]
// 账号权限 - 账号管理 - 多次会使用次表单，所以抽出
export const publicAccoutTableTitle = [
  {
    type: 'password',
    label: '登录密码',
    prop: 'password',
    maxlength: 20,
    showWordLimit: true,
    placeholder: '请输入登录密码',
    width: '100%',
  },
  {
    type: 'password',
    label: '重复密码',
    prop: 'resetPassword',
    maxlength: 20,
    showWordLimit: true,
    placeholder: '请输入重复密码',
    width: '100%',
  }
]
// 账号权限 - 账号管理 - 编辑账号表单
export const editAccoutTableTitle = [
  {
    type: 'slot',
    label: '所属机构',
    prop: 'orgId',
    width: '100%',
    disabled: true,
    placeholder: '请选择所属机构'
  },
  {
    type: 'select',
    label: '所属部门',
    prop: 'departmentId',
    width: '100%',
    disabled: true,
    placeholder: '请选择所属部门',
    options: [],
  },
  {
    type: 'select',
    label: '人员',
    prop: 'employeeId',
    width: '100%',
    disabled: true,
    placeholder: '请选择人员',
    eventName: 'employeeIdChange',
    options: [],
  },
  {
    type: 'input',
    label: '手机号',
    prop: 'contactTel',
    maxlength: 11,
    disabled: true,
    showWordLimit: true,
    placeholder: '请输入手机号',
    width: '100%',
  },
  {
    type: 'select',
    label: '所属角色',
    prop: 'roleId',
    width: '100%',
    placeholder: '请选择所属角色',
    options: [],
  },
  {
    type: 'input',
    label: '登录账号',
    prop: 'account',
    disabled: true,
    maxlength: 30,
    showWordLimit: true,
    placeholder: '请输入登录账号',
    width: '100%',
  }
]
// 账号权限 - 账号管理 - 添加账号表单
export const addAccoutFormTitle = [
  {
    type: 'slot',
    label: '所属机构',
    prop: 'orgId',
    width: '100%',
    placeholder: '请选择所属机构'
  },
  {
    type: 'select',
    label: '所属部门',
    prop: 'departmentId',
    width: '100%',
    placeholder: '请选择所属部门',
    options: [],
  },
  {
    type: 'select',
    label: '人员',
    prop: 'employeeId',
    width: '100%',
    placeholder: '请选择人员',
    eventName: 'employeeIdChange',
    options: [],
  },
  {
    type: 'input',
    label: '手机号',
    prop: 'contactTel',
    maxlength: 11,
    disabled: true,
    showWordLimit: true,
    placeholder: '请输入手机号',
    width: '100%',
  },
  {
    type: 'select',
    label: '所属角色',
    prop: 'roleId',
    width: '100%',
    placeholder: '请选择所属角色',
    options: [],
  },
  {
    type: 'input',
    label: '登录账号',
    prop: 'account',
    maxlength: 30,
    showWordLimit: true,
    placeholder: '请输入登录账号',
    width: '100%',
  },
  ...publicAccoutTableTitle,
]
// 账号权限 - 角色管理 - 添加角色表单规则
export const addAccoutFornRules = {
  orgId: [{ required: true, message: "请选择所属机构", trigger: "blur" }],
  departmentId: [{ required: true, message: "请选择所属部门", trigger: "blur" }],
  employeeId: [{ required: true, message: "请选择人员", trigger: "blur" }],
  contactTel: [{ required: true, message: "请输入手机号", trigger: "blur" }],
  roleId: [{ required: true, message: "请选择所属角色", trigger: "blur" }],
  account: [{ required: true, message: "请输入登录账号", trigger: "blur" }],
  password: [{ required: true, message: "请输入登录密码", trigger: "blur" }],
  resetPassword: [{ required: true, message: "请输入重复密码", trigger: "blur" }],
}

// 账号权限 - 账号管理 - 账号详情表单
export const accoutDetailTitle = [
  { type: 'view', label: '所属机构', prop: 'orgName', width: '100%' },
  { type: 'view', label: '所属部门', prop: 'departmentName', width: '100%' },
  { type: 'view', label: '人员姓名', prop: 'realName', width: '100%' },
  { type: 'view', label: '手机号', prop: 'contactTel', width: '100%' },
  { type: 'slot', label: '所属角色', prop: 'roles' },
  { type: 'view', label: '登录账号', prop: 'account', width: '100%' }
]
// 账号权限 - 账号管理 - 重置密码表单
export const passwordTableTitle = [
  {
    type: 'view',
    label: '姓名',
    prop: 'realName',
    width: '100%',
  },
  {
    type: 'view',
    label: '登录账号',
    prop: 'account',
    width: '100%',
  },
  ...publicAccoutTableTitle,
]

export const keyToLabel = (a, k) => {
  const _a = a.find(i => i.data == k)
  return _a ? _a.label : '--'
}