/*
 * @Descripttion: 设置 - 权限管理
 * @Author: hehua
 * @Date: 2024-03-19 13:43:49
 * @LastEditors: hehua
 * @LastEditTime: 2024-03-20 10:49:28
 */

const baseUrl = 'api/drsp_operation/access'

export default {
  ///************************权限组设置************************
  //查询权限组分页
  accessGroupPage: { url: `${baseUrl}/group/page`, method: 'post' },
  //获取权限组信息
  accessGroupInfo: { url: `${baseUrl}/group/info`, method: 'get' },
  //查询权限组授权树 - 超管
  getAccessGroupTree: { url: `${baseUrl}/group/tree`, method: 'get' },
  //查询租户权限组授权树 - 角色管理
  accessGroupTenantTree: { url: `${baseUrl}/group/tenant/tree`, method: 'get' },
  //权限组授权
  accessGroupGrant: { url: `${baseUrl}/group/grant`, method: 'post' },

}