/*
 * @Descripttion: 全局默认的基础路由
 * @Author: hehua
 * @Date: 2024-05-30 14:38:51
 * @LastEditors: hehua
 * @LastEditTime: 2024-06-11 11:13:38
 */

const baseRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/forget',
    name: 'Forget',
    component: () => import('@/views/login/forget.vue')
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: () => import('@/views/login/agreement.vue'),
    meta: { title: '个人信息保护声明' }
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    component: () => import('@/views/login/disclaimer.vue'),
    meta: { title: '免责协议' }
  },
  {
    path: '/loadingLogin',
    name: 'LoadingLogin',
    component: () => import('@/views/loadingLogin.vue'),
    meta: { title: '免责协议' }
  },
  // 此处以下为系统内置应用
  {
    path: '/cleverWorkOrder',
    key: 'cleverWorkOrder',
    component: () => import('@/views/applications/cleverWorkOrder.vue'),
  },
  {
    path: '/sparePartsManager',
    key: 'sparePartsManager',
    component: () => import('@/views/applications/sparePartsManager.vue'),
  },
  {
    path: '/performanceManagement',
    key: 'performanceManagement',
    component: () => import('@/views/applications/performanceManagement.vue'),
  },
  {
    path: '/maintenanceServices',
    key: 'maintenanceServices',
    component: () => import('@/views/applications/maintenanceServices.vue'),
  },
  {
    path: '/filingManage',
    key: 'filingManage',
    component: () => import('@/views/applications/filingManage.vue'),
  },
  // 绩效考核
  {
    path: '/accessGrade',
    key: 'accessGrade',
    component: () => import('@/views/applications/accessGrade.vue'),
  },
  // 绩效考核 新配置的页面 勿删
  {
    path: '/performance',
    key: 'performance',
    component: () => import('@/views/applications/accessGrade.vue'),
  },
  {
    path: '/workbench',
    key: 'workbench',
    component: () => import('@/views/applications/workbench.vue'),
  },
  // {
  //   path: '/employeeProfile',
  //   key: 'employeeProfile',
  //   component: () => import('@/views/employeeProfile/employeeProfile.vue'),
  //   meta: { title: '员工档案' }
  // },
  // {
  //   path: '/employeeProfile',
  //   key: 'employeeProfile',
  //   component: () => import('@/views/employeeProfile/StaffProfile.vue'),
  //   meta: { title: '员工档案' }
  // },

  // 巡检工单 - 2024-0611新增
  {
    path: '/inspectionOrder',
    key: 'inspectionOrder',
    component: () => import('@/views/applications/inspectionOrder.vue'),
  },
  { path: '/404', component: () => import('@/views/404'), meta: { hidden: true, roles: ['ADMIN', 'OPERATION', 'CUSTOMER'] } },
  // { path: '*', redirect: '/404', meta: { hidden: true, roles: ['ADMIN', 'OPERATION', 'CUSTOMER'] } },
]

export default baseRoutes