/*
 * @Descripttion: 在此引用一些自己注册的组件
 * @Author: hehua
 * @Date: 2024-02-29 18:46:24
 * @LastEditors: hehua
 * @LastEditTime: 2024-02-29 18:47:15
 */
import Vue from "vue"

import esConfirm from '@/components/esConfirm/index.js'
Vue.prototype.$esConfirm = esConfirm
