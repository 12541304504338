import storage from '@/store/storage'
import { Notification } from 'element-ui'
const app = {
  state: {
    accessToken: storage.getLocalItem('accessToken') || null, // 用户token
    userInfo: storage.getLocalItem('userInfo') || null, // 用户信息
    tenantId: storage.getLocalItem('tenantId') || null, // 用户信息
    deploymentType: storage.getSessionItem('deploymentType') || null, // 部署方式
    role: storage.getLocalItem('role') || null, //CUSTOMER //OPERATION //ADMIN
    // role: storage.getLocalItem('role') || 'ADMIN'
    num: 0, // 勿删
  },
  mutations: {
    setToken(state, e) {
      storage.setLocalItem('accessToken', e)
      state.accessToken = e
    },
    setUserInfo(state, e) {
      storage.setLocalItem('userInfo', e)
      storage.setLocalItem('tenantId', e.tenantId)
      state.userInfo = e
      state.tenantId = e.tenantId
    },
    setDeploymentType(state, e) {
      storage.setSessionItem('deploymentType', e)
      state.deploymentType = e
    },
    setRole(state, e) {
      storage.setLocalItem('role', e)
      // storage.setSessionItem('role', e)
      state.role = e
    },
    logOut(state) {
      state.accessToken = null
      state.userInfo = null
      localStorage.clear()
      sessionStorage.clear()
      window.location.reload()
    }
  },
  actions: {
    notification({ commit }, message) {
      Notification.error({
        title: '错误提示',
        message,
        customClass: 'error-message'
      })
    }
  },
  getters: {}
}

export default app
