import vue from "vue"
import store from "@/store"
import router from "@/router"

// 请求拦截器, 塞token
export function axiosRequestUse() {
  window.$axios.interceptors.request.use((config) => {
    if (store?.state?.user?.accessToken) {
      config.headers = Object.assign(config.headers, {
        Token: store?.state?.user?.accessToken || "",
        "tenant-id": store?.state?.user?.tenantId || 0,
      }) // 模拟修改请求头
    }
    return config // 检查完再把东西还给人家
  })
}

// 响应拦截器, 判断token失效跳转登录页
const lapseCode = [600, 601, 602]
export function axiosResponseUse() {
  window.$axios.interceptors.response.use((res) => {
    let customMsg = ""
    // !res.config.data instanceof FormData确保data不是FormData格式的
    if (res.config.data && !res.config.data instanceof FormData) {
      const _data = JSON.parse(res.config.data)
      customMsg = _data?.customMsg
    }
    if (
      "code" in res.data &&
      res.data.code != 200 &&
      res.data.code != 0 &&
      !customMsg
    ) {
      vue.prototype.$message.error(res.data?.msg || "系统出小差～")
    }
    // 601:token未携带,600:token失效,602:token非法，都跳转登录页
    if (lapseCode.includes(res.data.code)) {
      localStorage.clear()
      sessionStorage.clear()
      window.location.reload()
    }
    return res
  })
}
