/*
 * @Descripttion: 设置 - 服务设置
 * @Author: hehua
 * @Date: 2024-01-26 17:45:02
 * @LastEditors: hehua
 * @LastEditTime: 2024-06-27 11:12:25
 */
const baseUrl = 'api/drsp_elf'

export default {

  ///************************外部网点************************
  //批量切换网点类型 
  customerUpdateBatch: { url: `${baseUrl}/customer/update/batch`, method: 'post', },

  ///************************关联配置************************
  //获取服务商工单配置信息
  getConfigOrderInfo: { url: `${baseUrl}/provider/config/order/info`, method: 'get', },
  //设置服务商工单配置信息
  setConfigOrderInfo: { url: `${baseUrl}/provider/config/order/setting`, method: 'post', },
  //获取网点列表
  getProviderChildList: { url: `${baseUrl}/provider/child`, method: 'get', isParam: true },
  //删除网点
  providerDelete: { url: `${baseUrl}/provider/delete`, method: 'post', },

  ///************************备案申请************************
  //获取网点员工列表  不分页
  getEmployeeList: { url: `${baseUrl}/provider/employee`, method: 'get', isParam: true },
  //获取网点员工详情
  getEmployeeDetail: { url: `${baseUrl}/provider`, method: 'get', isParam: true },
  //分页查询备案申请的记录列表
  getRecordApplyPage: { url: `${baseUrl}/provider/employee/record_apply/page`, method: 'post' },
  //获取员工审核申请结果列表
  getRecordApplyEmployee: { url: `${baseUrl}/provider/employee/record_apply`, method: 'get', isParam: true },
  //员工备案申请操作
  employeeApply: { url: `${baseUrl}/provider/employee/apply`, method: 'post' },

  ///************************时效设置************************
  //获取时效设置
  getTimelinessConfig: { url: `${baseUrl}/order/timeliness/config/list`, method: 'post' },
  //设置时效设置
  setTimelinessConfig: { url: `${baseUrl}/order/timeliness/config/setting`, method: 'post' },
}